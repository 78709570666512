import React from 'react'
import { Modal } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'

const ConfirmDeleteModal = (
  { onClose, show, thingToRemoveType, thingToRemoveName, onConfirm, prepo = "al" }
) => {
  return (
    <Modal show={ show } onHide={ onClose }>
      <Modal.Header closeButton >
        <Modal.Title>Confirmar eliminación de { thingToRemoveType }</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        Esta seguro que quiere eliminar { prepo } { thingToRemoveType } { thingToRemoveName }
      </Modal.Body>
      <Modal.Footer >
        <Button bsStyle="default" fill type="button" style={ styles.saveButton } onClick={ onClose }>
          <i className="fa fa-ban"></i> Cancelar
          </Button>
        <Button bsStyle="danger" fill type="button" style={ styles.saveButton } onClick={ onConfirm }>
          <i className="fa fa-trash"></i> Confirmar eliminación
          </Button>
      </Modal.Footer>
    </Modal >
  );
};
export default ConfirmDeleteModal;