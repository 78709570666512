import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup
} from "react-bootstrap";

import { generalRoute } from "variables/DomainRoutes.jsx";
import styles from './styles'
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import LoadingModal from "components/Modal/LoadingModal";
import { payRoute } from "variables/DomainRoutes.jsx";
class MyMessagesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      newAnswer: '',
      isLoadingModal: false
    };
  }
  async componentDidMount() {
    this.markAsRead();
  }

  markAsRead = async () => {
    if (this.props.messageData.viewed === null) {
      this.setState({ isLoading: true });
      try {
        const response = await axios.post('message/tutor/mark_read', {
          id: this.props.messageData.id,
        });
        console.log(response)
      } catch (err) {
        console.log('ERROR', err);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  }
  answer = async (messageId, type) => {

    this.setState({ isLoadingModal: true });
    try {
      let answer = '';
      if (type === 1) {
        answer = "Si";
      } else {
        answer = "No";
      }
      const response = await axios.post('/message/fast_response', {
        message_id: messageId,
        fast_response: answer
      });
      this.setState({ newAnswer: answer });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoadingModal: false });
    }
  }
  render() {
    const {
      messageData,
      returnToList
    } = this.props;
    const {
      newAnswer,
      isLoadingModal
    } = this.state;

    return (
      <>
        <LoadingModal
          show={ isLoadingModal }
          text="Enviando respuesta ..."
        />
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row style={ { marginTop: "2%" } }>
              <Col md={ 6 }>
                <h4>Detalle del mensaje</h4>
                <h2><img alt={ messageData.message.type_details.icon } src={ generalRoute + messageData.message.type_details.icon } style={ styles.iconList } /> { messageData.message.type_details.name }</h2>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <span dangerouslySetInnerHTML={ { __html: messageData.message.body } } />
                  </Col>
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <h4>Recibido</h4>
                <Col md={ 12 } style={ { marginBottom: "5%" } }>
                  { messageData.created_at }
                </Col>
                { messageData.message.attachment &&
                  <>
                    <h4>Adjuntos</h4>
                    <Col md={ 12 }>
                      <ul style={ { paddingLeft: "1%" } }>

                        { messageData.message.attachment.split(',').map(
                          (attached) => {
                            return (
                              <li>
                                <a
                                  href={ generalRoute + attached }
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  { attached }
                                </a>
                              </li>
                            )
                          }) }
                      </ul>
                    </Col>
                  </>
                }
                { messageData.message.url1 &&
                  <>
                    <h4>URLs</h4>
                    <Col md={ 12 }>
                      <ul style={ { paddingLeft: "1%" } }>
                        { messageData.message.url1 && <li>

                          <a
                            href={ messageData.message.url1 }
                            target="_blank"
                            rel="noopener noreferrer">
                            { messageData.message.url1 }
                          </a>
                        </li> }
                        { messageData.message.url2 && <li><a
                          href={ messageData.message.url2 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { messageData.message.url2 }
                        </a></li> }
                        { messageData.message.url3 && <li><a
                          href={ messageData.message.url3 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { messageData.message.url3 }
                        </a></li> }
                        { messageData.url4 && <li><a
                          href={ messageData.message.url4 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { messageData.message.url4 }
                        </a></li> }
                        { messageData.message.url5 && <li><a
                          href={ messageData.message.url5 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { messageData.message.url5 }
                        </a></li> }
                      </ul>
                    </Col>
                  </>
                }
                { messageData.message.fast_response === true &&
                  <>
                    <h4>Respuesta rápida</h4>
                    { newAnswer === '' ?
                      <Col md={ 12 }>
                        { messageData.fast_response ?
                          messageData.fast_response.fast_response
                          : "Pendiente"
                        }
                      </Col>
                      :
                      <Col md={ 12 }>
                        { newAnswer }
                      </Col>
                    }
                  </>
                }
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullLeft fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
                {
                  messageData.message.payment !== null && messageData.message.payment.status === false &&
                  <>
                    <a
                      href={ payRoute + messageData.message.payment.code }
                      target="_blank"
                      rel="noopener noreferrer">
                      <Button bsStyle="warning" pullLeft fill type="button" style={ styles.saveButton }>
                        <i className="fa fa-credit-card"></i> Realizar pago
                      </Button>
                    </a>
                  </>
                }

                { messageData.message.fast_response === true && messageData.fast_response === null && newAnswer === '' &&
                  <>
                    <Button bsStyle="success" pullLeft fill type="button" style={ styles.saveButton } onClick={ () => this.answer(messageData.id, 1) }>
                      <i className="fa fa-check"></i> Responder Si
                </Button>
                    <Button bsStyle="danger" pullLeft fill type="button" style={ styles.saveButton } onClick={ () => this.answer(messageData.id, 0) }>
                      <i className="fa fa-times"></i> Responder No
                </Button>
                  </>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default MyMessagesDetail;
