import React, { Component } from "react";
import {
    FormControl,
    Col
} from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';

class UrlItem extends Component {
    _remove = () => {
        if (this.props.onRemove)
            this.props.onRemove();
    }

    onChange(e) {
        this.props.onChangeUrl(e);
    }
    render() {
        const {
            name,
            canDelete
        } = this.props;
        return (
            <Col md={ 8 } style={ { margin: "1%" } }>
                <Col md={ 10 }>
                    <FormControl type="text" name={ "url" + name } onChange={ e => this.onChange(e) } />
                </Col>
                {
                    canDelete &&
                    <Button bsStyle="danger" bsSize="xs" fill onClick={ () => this._remove(this) } style={ { marginTop: "2%" } }>
                        <i className="fa fa-trash"></i>
                    </Button>
                }

            </Col>
        )
    }
}

export default UrlItem;