import React, { Component } from "react";
import { Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner';
import ModalNotification from 'components/Notifications/ModalNotification.jsx'

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      profileData: {},
      showNotification: false,
      notificationMessage: "Datos actualizados con éxito."
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const localUser = JSON.parse(localStorage.getItem('USER'))
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('user/details', { user_id: localUser.id });
      this.setState({ profileData: response.data.user });

      console.log(response.data);
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { profileData, isLoading, showNotification, notificationMessage } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={ !isLoading ? (
            <>
              <Row>
                <Col md={ 12 } style={ { padding: "2%", marginLeft: "3%" } }>
                  <Row >
                    <Col md={ 10 }>
                      { showNotification &&
                        <ModalNotification
                          type="success"
                          notificationMessage={ notificationMessage }
                          closeNotification={ () => this.setState({ showNotification: false }) }
                        />
                      }
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> NOMBRES </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 }> { profileData.name }</Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> APELLIDOS </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 }> { profileData.last_name }</Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> CORREO </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 }> { profileData.email }</Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> ROL </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 }> { profileData.rol && profileData.rol.name }</Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { marginLeft: "5%" } }>
                  <Button bsStyle="info" fill type="button" style={ styles.saveButton }
                    onClick={ () => this.props.history.replace('/admin/conf/edit-profile', { profileData }) }>
                    <i className="fa fa-edit"></i> Editar Perfil
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
              <Loader
                type="Rings"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                style={ { display: 'flex', justifyContent: 'center' } }
              />
            )
          } />
      </div>
    );
  }
}

export default Settings;
