module.exports = {
  saveButton: {
    marginTop: "5%",
    marginRight: "2%",
    marginLeft: "1%"
  },
  massive: {
    marginTop: "1%",
    marginLeft: "1.5%",
    width: "72.25%",
    marginBottom: "1%"
  },
  content: {
    marginTop: "2%"
  },
  header_a: {
    marginLeft: "3.5%"
  },
  header_b: {
    marginLeft: "5%"
  },
  data: {
    marginLeft: "2%"
  },

  topButton: {
    margin: "1%",
    marginTop: "5%"
  }
}