import React from "react";
import { Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import { uploadRoute, generalRoute } from "variables/DomainRoutes.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner';

class ConfSchoolEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      description: '',
      bank: '',
      account: '',
      nameBenefit: '',
      file: null,
      oldFile: '',
      privacyPolicies: '',
      schoolDataLicense: '',
      isLoading: false,
      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeFile = (e) => {
    this.setState({ file: e.target.files[0] })
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('institution/details');
      const schoolData = response.data.institution;
      this.setState({
        id: schoolData.id,
        name: schoolData.name,
        description: schoolData.description,
        bank: schoolData.bank,
        account: schoolData.bank_account,
        nameBenefit: schoolData.beneficiary_name,
        privacyPolicies: schoolData.privacy_policies,
        oldFile: schoolData.privacy_policies,
        schoolDataLicense: schoolData.license_start_date
      })

    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      description,
      bank,
      account,
      nameBenefit,
      file,
      oldFile
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      let filename = "";
      if (file !== null) {
        const formData = new FormData();
        formData.append('general_file', file)
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
        filename = await axios.post(uploadRoute + 'general/upload', formData, config);
        filename = filename.data.filename
      }
      if (filename === "") {
        await axios.post('institution/edit', {
          name,
          description,
          bank,
          bank_account: account,
          beneficiary_name: nameBenefit,
          privacy_policies: oldFile,
        });
      } else {
        await axios.post('institution/edit', {
          name,
          description,
          bank,
          bank_account: account,
          beneficiary_name: nameBenefit,
          privacy_policies: filename,
        });
      }

      this.props.history.replace('/admin/conf/school');
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        { (!this.state.isSubmitting || !this.state.isLoading) ? (
          <form onSubmit={ this.handleSubmit }>
            <Card
              cardStyle={ {
                boxShadow: 'none',
              } }
              content={
                <>
                  <Row>
                    <Col md={ 12 } style={ { padding: "2%", marginLeft: "3%" } }>
                      <Row >
                        <Col md={ 10 }>
                          { this.state.showNotification &&
                            <ErrorNotification
                              notificationMessage={ this.state.notificationMessage }
                              closeNotification={ () => this.setState({ showNotification: false }) }
                            />
                          }
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> NOMBRE </ControlLabel>
                            </Col>
                            <Col md={ 8 } style={ styles.dataN1 }>
                              <FormControl required type="text" name="name" value={ this.state.name } onChange={ e => this.onChange(e) } />
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> DESCRIPCIÓN </ControlLabel>
                            </Col>
                            <Col md={ 12 } style={ styles.dataN1 } >
                              <textarea required name="description" value={ this.state.description } onChange={ e => this.onChange(e) } style={ { width: "65%", height: "110px" } } />
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> FECHA DE INICIO DE LICENCIA </ControlLabel>
                            </Col>
                            <Col md={ 12 } style={ styles.dataN1 }> { this.state.schoolDataLicense }</Col>
                          </FormGroup>
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> DATOS BANCARIOS </ControlLabel>
                            </Col>
                            <Col md={ 6 }>
                              <Col md={ 12 }>
                                <Col md={ 4 }> <ControlLabel > BANCO </ControlLabel> </Col>
                                <Col md={ 8 } style={ styles.dataN2 }>
                                  <FormControl type="text" name="bank" value={ this.state.bank } onChange={ e => this.onChange(e) } />
                                </Col>
                              </Col>
                              <Col md={ 12 }>
                                <Col md={ 4 }> <ControlLabel > CUENTA </ControlLabel> </Col>
                                <Col md={ 8 } style={ styles.dataN2 }>
                                  <FormControl type="text" name="account" value={ this.state.account } onChange={ e => this.onChange(e) } />
                                </Col>
                              </Col>
                              <Col md={ 12 }>
                                <Col md={ 4 }> <ControlLabel > BENEFICIARIO </ControlLabel> </Col>
                                <Col md={ 8 } style={ styles.dataN2 } >
                                  <FormControl type="text" name="nameBenefit" value={ this.state.nameBenefit } onChange={ e => this.onChange(e) } />
                                </Col>

                              </Col>
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> POLÍTICAS DE PRIVACIDAD </ControlLabel>
                            </Col>
                            <Col md={ 8 } style={ styles.dataN1 }>
                              { this.state.privacyPolicies === "" ?
                                <>
                                  <label>No cuenta  con políticas de privacidad actuales</label>
                                </>
                                :
                                <>
                                  <a
                                    href={ generalRoute + this.state.privacyPolicies }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    haga click para ver las políticas de privacidad actuales
                                  </a>
                                  <br />
                                  <label>Si desea puede cambiarlas: </label>
                                </>
                              }

                              <input type="file" className="form-control" accept="application/pdf" onChange={ this.onChangeFile }></input>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 12 } style={ { marginLeft: "5%" } }>
                      <Button bsStyle="danger" fill type="button" style={ styles.saveButton } onClick={ () => this.props.history.replace('/admin/conf/school') }>
                        <i className="fa fa-chevron-left"></i> Regresar
                        </Button>
                      <Button bsStyle="success" fill type="submit" style={ { marginTop: "2%", marginLeft: "1%" } }>
                        <i className="fa fa-save"></i> Guardar cambios
                        </Button>
                    </Col>
                  </Row>
                </>
              }
            />
          </form>
        ) : (
            <Loader
              type="Rings"
              color="#00BFFF"
              height={ 100 }
              width={ 100 }
              style={ { display: 'flex', justifyContent: 'center' } }
            />
          ) }
      </div>
    );
  }
}

export default ConfSchoolEdit;
