

import React from 'react'
import {
  Row,
  Col,
  Grid
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class PayPenalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      penalty: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: ''
    };
  }

  componentDidMount() {
    this.fetchPenalty()
  }

  fetchPenalty = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/payment/get/penalty');
      this.setState({ penalty: response.data.penalty.penalty_payment });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      penalty,
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      await axios.post('/payment/edit/penalty', {
        penalty_payment: penalty,
      });
      this.setState({
        showNotification: true,
        notificationType: "success",
        notificationMessage: "Penalidad actualizada."
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      notificationType,
      isSubmitting,
      penalty,
      isLoading
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                !isSubmitting ? (
                  <>
                    <form onSubmit={ this.handleSubmit }>
                      <Row>
                        <Col md={ 12 } style={ { padding: "2%" } }>
                          <h4>Modificar penalidad</h4>
                          { showNotification &&
                            <ModalNotification
                              notificationMessage={ notificationMessage }
                              closeNotification={ () => this.setState({ showNotification: false }) }
                              type={ notificationType }
                            />
                          }
                          {
                            isLoading ?
                              <Loader
                                type="Rings"
                                color="#00BFFF"
                                height={ 100 }
                                width={ 100 }
                                style={ { display: 'flex', justifyContent: 'center' } }
                              />
                              :
                              <Row style={ { marginTop: "2%" } }>
                                <Col md={ 4 }>
                                  <FormGroup>
                                    <ControlLabel> Penalidad (%) </ControlLabel>
                                    <FormControl type="text" name="penalty" value={ penalty } onChange={ e => this.onChange(e) } required />
                                  </FormGroup>
                                </Col>
                              </Row>
                          }

                          <Row>
                            <Col md={ 12 } style={ { marginLeft: "0px" } }>
                              <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                                <i className="fa fa-save"></i> Guardar
                                </Button>
                              <Button bsStyle="danger" pullRight fill type="button" onClick={ () => this.props.history.replace("/admin/pay/index") } style={ styles.saveButton }>
                                <i className="fa fa-ban"></i> Cancelar
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  </>
                ) : (
                    <Loader
                      type="Rings"
                      color="#00BFFF"
                      height={ 100 }
                      width={ 100 }
                      style={ { display: 'flex', justifyContent: 'center' } }
                    />
                  )
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default PayPenalty;