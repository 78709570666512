import React from "react";
import { Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner';

class ConfProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      lastName: '',
      email: '',
      roleId: '',
      isLoading: false,
      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const localUser = JSON.parse(localStorage.getItem('USER'))
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('user/details', { user_id: localUser.id });
      const userData = response.data.user
      this.setState({
        id: userData.id,
        name: userData.name,
        lastName: userData.last_name,
        email: userData.email,
        roleId: userData.role_id
      })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      id,
      name,
      lastName,
      email,
      roleId
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      await axios.post(`user/edit/${id}`, {
        name: name,
        last_name: lastName,
        email,
        role_id: roleId,
      });
      this.props.history.replace({ pathname: '/admin/conf/profile', showNotification: true });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      name,
      lastName,
      email,
      showNotification,
      notificationMessage,
      isSubmitting,
      isLoading
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        { (!isSubmitting || isLoading) ? (
          <form onSubmit={ this.handleSubmit }>
            <Card
              cardStyle={ {
                boxShadow: 'none',
              } }
              content={
                <>
                  <Row>
                    <Col md={ 12 } style={ { padding: "2%", marginLeft: "3%" } }>
                      <Row >
                        <Col md={ 10 }>
                          { showNotification &&
                            <ErrorNotification
                              notificationMessage={ notificationMessage }
                              closeNotification={ () => this.setState({ showNotification: false }) }
                            />
                          }
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> NOMBRES </ControlLabel>
                            </Col>
                            <Col md={ 8 } style={ styles.dataN1 }>
                              <FormControl required type="text" name="name" value={ name } onChange={ e => this.onChange(e) } />
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> APELLIDOS </ControlLabel>
                            </Col>
                            <Col md={ 8 } style={ styles.dataN1 }>
                              <FormControl required type="text" name="lastName" value={ lastName } onChange={ e => this.onChange(e) } />
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Col md={ 12 }>
                              <ControlLabel> EMAIL </ControlLabel>
                            </Col>
                            <Col md={ 8 } style={ styles.dataN1 }>
                              <span>{ email }</span>
                            </Col>
                          </FormGroup>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 12 } style={ { marginLeft: "5%" } }>
                      <Button bsStyle="danger" fill type="button" style={ styles.saveButton } onClick={ () => this.props.history.replace('/admin/conf/profile') }>
                        <i className="fa fa-chevron-left"></i> Regresar
                    </Button>
                      <Button bsStyle="success" fill type="submit" style={ { marginTop: "2%", marginLeft: "1%" } }>
                        <i className="fa fa-save"></i> Guardar cambios
                    </Button>
                    </Col>
                  </Row>
                </>
              }
            />
          </form>
        ) : (
            <Loader
              type="Rings"
              color="#00BFFF"
              height={ 100 }
              width={ 100 }
              style={ { display: 'flex', justifyContent: 'center' } }
            />
          ) }
      </div>
    );
  }
}

export default ConfProfileEdit;
