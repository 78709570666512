

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import avatar from "assets/img/default-avatar.png";
import { generalRoute } from "variables/DomainRoutes.jsx";

class StudentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      returnToList,
      studentData
    } = this.props;
    return (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row>
              <Col md={ 4 }>
                <h4>Datos del Estudiante</h4>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > NOMBRES</ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > APELLIDOS</ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.last_name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > MATRÍCULA</ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.code } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > NOMBRE DEL PADRE</ControlLabel> </Col>
                    <Col md={ 8 }>{ studentData.tutor.name } { studentData.tutor.last_name }  </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > SEXO</ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.gender === true ? "Femenino" : "Masculino" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > NIVEL </ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.level && studentData.level.name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > GRADO </ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.grade && studentData.grade.name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > GRUPO</ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.group && studentData.group.name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel >  CREADO </ControlLabel> </Col>
                    <Col md={ 8 }> { studentData.created_at }</Col>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={ 4 }>
                <h4>Datos adicionales</h4>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 5 }> <ControlLabel >  CONTACTO DE EMERGENCIA 1 </ControlLabel> </Col>
                    <Col md={ 7 }> { studentData.contact1 ? studentData.contact1 : "no registrado" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 5 }> <ControlLabel >  TELÉFONO 1 </ControlLabel> </Col>
                    <Col md={ 7 }> { studentData.phone1 ? studentData.phone1 : "no registrado" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 5 }> <ControlLabel >  CONTACTO DE EMERGENCIA 2 </ControlLabel> </Col>
                    <Col md={ 7 }> { studentData.contact2 ? studentData.contact2 : "no registrado" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 5 }> <ControlLabel >  TELÉFONO 2 </ControlLabel> </Col>
                    <Col md={ 7 }> { studentData.phone2 ? studentData.phone2 : "no registrado" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 5 }> <ControlLabel >  TIPO DE SANGRE </ControlLabel> </Col>
                    <Col md={ 7 }> { studentData.blood_type ? studentData.blood_type : "no registrado" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 5 }> <ControlLabel > ALERGIAS </ControlLabel> </Col>
                    <Col md={ 7 }> { studentData.allergies ? studentData.allergies : "no registrado" }</Col>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={ 4 }>
                <img src={
                  studentData.photo ?
                    generalRoute + studentData.photo : avatar
                }
                  alt="foto-de-estudiante"
                  style={ { width: "60%", marginTop: "15%" } } />
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ () => returnToList(this.props.currentPage) } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
export default StudentDetail