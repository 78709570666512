import React from 'react'
import { Modal, FormControl, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Select from 'react-select'

class DetailMonitorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      attributeId: '',
      monitorId: '',
      attributes: [],
      isSubmitting: false
    };
  }

  componentDidMount() {
    const monitorData = this.props.monitorDetailData;
    if (monitorData !== null) {
      this.setState({
        id: monitorData.id,
        name: monitorData.name,
        attributeId: monitorData.attribute_id
      })
    }
    this.setState({ monitorId: this.props.monitorId })
    this.fetchAttributesId()
  }
  fetchAttributesId = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('attribute/list');
      const newData = response.data.attributes.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ attributes: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, name, attributeId, monitorId } = this.state;
    this.setState({ isSubmitting: true });
    try {
      if (this.props.monitorDetailData === null) {
        await axios.post('monitory/add/details', {
          monitory_id: monitorId,
          attribute_id: attributeId,
          name
        });
      } else {
        await axios.post('monitory/editdetails', {
          monitory_detail_id: id,
          attribute_id: attributeId,
          name
        });
      }
      this.props.reload();
      this.props.onClose();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }

  }

  getIdValue = (attributes) => {
    const { attributeId } = this.state;
    const filterResult = attributes.filter(({ value }) => value === attributeId);
    if (filterResult.length === 0) {
      return []
    }
    return filterResult
  }

  render() {
    const {
      onClose,
      show,
      monitorData
    } = this.props;
    const {
      name,
      isSubmitting,
      showNotification,
      notificationMessage,
      attributes,
      isLoading
    } = this.state;
    return (
      <Modal show={ show } onHide={ onClose } backdrop="static">
        <form onSubmit={ this.handleSubmit }>
          <Modal.Header closeButton >
            <Modal.Title>
              { monitorData === null ?
                "Agregar un detalle de seguimiento" : "Editar un detalle de seguimiento" }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={ { wordWrap: "break-word" } }>
            <Row>
              <Col md={ 10 }>
                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
                { !isSubmitting ? (
                  <>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel style={ styles.labelModal }> ATRIBUTO </ControlLabel>
                        { !isLoading ?
                          <Col md={ 12 }>
                            <Select options={ attributes }
                              name="attributeId" className="basic-single"
                              classNamePrefix="select"
                              onChange={ (e) => this.setState({ 'attributeId': e.value }) }
                              placeholder="Seleccione un atributo"
                              value={ attributes && this.getIdValue(attributes) }
                            />
                          </Col>
                          :
                          <Loader
                            type="Rings"
                            color="#00BFFF"
                            height={ 30 }
                            width={ 30 }
                            style={ { display: 'flex', justifyContent: 'center' } }
                          />
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel style={ styles.labelModal }> NOMBRE</ControlLabel>
                        <Col md={ 12 }>
                          <FormControl
                            required
                            name="name"
                            value={ name }
                            onChange={ (e) => this.setState({ name: e.target.value }) }
                          />
                        </Col>
                      </Col>
                    </FormGroup>
                  </>
                ) : (
                    <Loader
                      type="Rings"
                      color="#00BFFF"
                      height={ 100 }
                      width={ 100 }
                      style={ { display: 'flex', justifyContent: 'center' } }
                    />
                  ) }


              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer >
            <Button bsStyle="success" fill type="submit" style={ styles.saveButton }>
              <i className="fa fa-save"></i> Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal >
    );
  }
}
export default DetailMonitorModal