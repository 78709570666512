import React, { Component } from "react";
import { Row, Col, Table, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import { homeworkStatus } from "variables/Variables.jsx";
import { generalRoute } from "variables/DomainRoutes.jsx";
import Loader from 'react-loader-spinner'
import ShowAttachedModal from "components/Modal/ShowAttachedModal";

class MyHomeworkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      homeworkStudents: [],
      showAttachedModal: false,
      attacheds: '',
      titleAttached: '',
      typeShowModal: '',
      currentPage: 1,
      totalOfRecords: 0,
      totalOfPages: 0
    };
  }
  async componentDidMount() {
    this.fetchHomeworkDetail();
  }

  fetchHomeworkDetail = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/homework_student/list_homeworks', {
        current_page: currentPage,
      });
      this.setState({
        homeworkStudents: response.data.homework_student.data,
        totalOfPages: response.data.homework_student.last_page,
        totalOfRecords: response.data.homework_student.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleShowModal = (homework, type, num) => {
    if (num > 0) {
      if (type === "urls") {
        this.setState({
          showAttachedModal: true,
          attacheds: homework,
          titleAttached: 'Se adjuntaron las siguientes urls',
          typeShowModal: type
        });
      } else {
        this.setState({
          showAttachedModal: true,
          attacheds: homework,
          titleAttached: 'Se adjuntaron los siguientes archivos',
          typeShowModal: type
        });
      }
    }
  }

  renderBudge = (status) => {
    switch (status) {
      case 0:
        return "label label-default";
      case 1:
        return "label label-warning";
      case 2:
        return "label label-success";
      case 3:
        return "label label-danger";
      default:
        return "label label-default";
    }
  }
  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchHomeworkDetail() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchHomeworkDetail() }
    );
  }
  render() {
    const {
      editHomework
    } = this.props;
    const {
      isLoading,
      showAttachedModal,
      attacheds,
      titleAttached,
      typeShowModal,
      homeworkStudents,
      totalOfPages,
      totalOfRecords,
      currentPage
    } = this.state;
    return !isLoading ? (
      <>
        <Row style={ { padding: "2%" } }>
          <h4>Mis tareas</h4>
          <Row style={ { marginTop: "1%" } }>
            <Col md={ 12 }>
              {
                showAttachedModal &&
                <ShowAttachedModal
                  title={ titleAttached }
                  attacheds={ attacheds }
                  onClose={ () => this.setState({ showAttachedModal: false }) }
                  show={ showAttachedModal }
                  route={ generalRoute }
                  type={ typeShowModal }
                />
              }
              <Table striped hover >
                <thead>
                  <tr>
                    <th>Materia </th>
                    <th>alumno</th>
                    <th>imágenes</th>
                    <th>documentos</th>
                    <th>mensaje</th>
                    <th>observación</th>
                    <th>estado</th>
                    <th>creado</th>
                    <th>acciones</th>
                  </tr>
                </thead>
                <tbody>
                  { homeworkStudents.map((det) => {
                    const statusAsString = typeof homeworkStatus[det.status] !== 'undefined' ? homeworkStatus[det.status] : '';
                    return (
                      <tr key={ det.id }>
                        <td>{ det.homework && det.homework.course && det.homework.course.name }</td>
                        <td>{ det.student && `${det.student.name} ${det.student.last_name}` }</td>
                        <td>
                          <span className="label label-info"
                            onClick={ () => this.handleShowModal(det, "pics", det.pics ? det.pics.split(',').length : 0) }>
                            <i className="fa fa-image"></i>
                            {
                              det.pics ? det.pics.split(',').length : 0
                            }
                          </span>
                        </td>
                        <td>
                          <span className="label label-primary"
                            onClick={ () => this.handleShowModal(det, "docs", det.docs ? det.docs.split(',').length : 0) }>
                            <i className="fa fa-paperclip"></i>{
                              det.docs ? det.docs.split(',').length : 0
                            }
                          </span>
                        </td>
                        <td>{ det.msg }</td>
                        <td>{ det.obs }</td>
                        <td>
                          <span className={ this.renderBudge(det.status) }>
                            { statusAsString }
                          </span>
                        </td>
                        <td>{ det.created_at }</td>
                        <td>
                          <Button
                            bsStyle="success"
                            bsSize="sm"
                            fill title="Responder Tarea"
                            style={ { display: (det.status === 0 || det.status === 3 || det.status === 1) ? "block" : "none" } }
                            onClick={ () => editHomework(det) }
                          >
                            <i className="fa fa-edit"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  }) }
                </tbody>
              </Table>
              { homeworkStudents.length > 0 && (
                <>
                  <Pager>
                    <Pager.Item
                      previous
                      disabled={ currentPage === 1 }
                      onClick={ () => this.goToPreviousPage() }
                    >
                      <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                    <Pager.Item>

                      { currentPage }/{ totalOfPages }

                    </Pager.Item>
                    <Pager.Item
                      disabled={ currentPage === totalOfPages }
                      next
                      onClick={ () => this.goToNextPage() }
                    >
                      Siguiente <i className="fa fa-chevron-right"></i>
                    </Pager.Item>
                  </Pager>
                  <Pager>
                    <Pager.Item>
                      Total: { totalOfRecords } registros
                </Pager.Item>
                  </Pager>
                </>
              ) }
            </Col>
          </Row>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MyHomeworkList;
