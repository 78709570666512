import React from 'react'
import { Modal, FormControl, Row, Col, FormGroup, ControlLabel, Alert } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'

class ChangePassUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
      password: '',
      passwordConfirm: '',
      showNotification: false,
      notificationMessage: '',
    };
  }

  validateAndSubmit = (e) => {
    e.preventDefault();
    const { onChangePassword } = this.props;
    const { password, passwordConfirm } = this.state;
    if (password !== passwordConfirm) {
      this.setState({ showNotification: true, notificationMessage: 'Las contraseñas no coinciden' });
      return;
    }
    if (password.length < 6) {
      this.setState({
        showNotification: true,
        notificationMessage: "La contraseña debe contener almenos 6 caracteres"
      });
      return;
    }
    onChangePassword(password);
  }

  render() {
    const {
      onClose,
      show,
      userName
    } = this.props;
    const {
      seePassword,
      password,
      passwordConfirm,
      showNotification,
      notificationMessage,
    } = this.state;
    return (
      <Modal show={ show } onHide={ onClose }>
        <form onSubmit={ this.validateAndSubmit }>
          <Modal.Header closeButton >
            <Modal.Title>Cambiar contraseña al usuario { userName }</Modal.Title>
          </Modal.Header>
          <Modal.Body style={ { wordWrap: "break-word" } }>
            { showNotification && (
              <Alert bsStyle="danger">
                <span>{ notificationMessage }</span>
                {/* TODO fix close button
                  <button type="button" aria-hidden="true" className="close" onClick={() => this.setState({showNotification: false})}>
                    &#x2715;
                  </button>
                */}
              </Alert>
            ) }
            <Row>
              <Col md={ 10 }>
                <FormGroup>
                  <Col md={ 12 }>
                    <ControlLabel style={ styles.labelModal }> NUEVA CONTRASEÑA </ControlLabel>
                  </Col>
                  <Col md={ 11 }>
                    <FormControl
                      required
                      type={ seePassword === false ? "password" : "text" }
                      name="password"
                      value={ password }
                      onChange={ (e) => this.setState({ password: e.target.value }) }
                    />
                  </Col>
                  <Col md={ 1 }>
                    {
                      seePassword === false ?
                        (<Button bsStyle="default" style={ styles.passButton } onClick={ () => this.setState({ seePassword: true }) }><i className="fa fa-eye"></i></Button>) :
                        (<Button bsStyle="primary" style={ styles.passButton } onClick={ () => this.setState({ seePassword: false }) }><i className="fa fa-eye-slash"></i></Button>)
                    }
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 }>
                    <ControlLabel style={ styles.labelModal }> CONFIRMAR NUEVA CONTRASEÑA </ControlLabel>
                    <FormControl
                      required
                      type="password"
                      name="passwordConfirm"
                      value={ passwordConfirm }
                      onChange={ (e) => this.setState({ passwordConfirm: e.target.value }) }
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer >
            <Button bsStyle="success" fill type="submit" style={ styles.saveButton }>
              <i className="fa fa-refresh"></i> Actualizar contraseña
            </Button>
          </Modal.Footer>
        </form>
      </Modal >
    );
  }
}
export default ChangePassUserModal