import React, { Component } from "react";
import { Row, Col, Table, FormGroup, ControlLabel, } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import { thMessageHistoryArray, deliveryTypes, periodTimes } from "variables/Variables.jsx";
import { generalRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class MessageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      message: {},
      details: []
    };
  }
  async componentDidMount() {
    this.fetchMessageDetail();
  }

  fetchMessageDetail = async () => {
    this.setState({ isLoading: true });
    const { messageData: { id } } = this.props;
    try {
      const response = await axios.post('/message/details', {
        message_id: id
      });
      this.setState({ message: response.data.message, details: response.data.details });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      returnToList,
      messageData
    } = this.props;
    const {
      isLoading,
      message,
      details
    } = this.state;
    return !isLoading ? (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row>
              <h4>Detalle del Mensaje</h4>
              <Col md={ 6 }>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > TIPO DE MENSAJE </ControlLabel> </Col>
                    <Col md={ 8 }> { messageData.type_details.name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > ASUNTO </ControlLabel> </Col>
                    <Col md={ 8 }> { message.subject } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > TIPO DE ENVÍO </ControlLabel> </Col>
                    <Col md={ 8 }> { typeof deliveryTypes[message.type] !== 'undefined' ? deliveryTypes[messageData.type] : '' } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > N. DESTINATARIOS </ControlLabel> </Col>
                    <Col md={ 8 }> { message.count_destiny } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > FECHA Y HORA DE ENVÍO </ControlLabel> </Col>
                    <Col md={ 8 }> { message.created_at } </Col>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > MENSAJE </ControlLabel> </Col><br />
                    <Col md={ 12 }
                      dangerouslySetInnerHTML={ { __html: message.body } } />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > URLS </ControlLabel> </Col>
                    <Col md={ 8 }>
                      { message.url1 ? "" : "No tiene urls adjuntas" }
                      <ul style={ { paddingLeft: "1%" } }>
                        { message.url1 && <li>

                          <a
                            href={ message.url1 }
                            target="_blank"
                            rel="noopener noreferrer">
                            { message.url1 }
                          </a>
                        </li> }
                        { message.url2 && <li><a
                          href={ message.url2 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { message.url2 }
                        </a></li> }
                        { message.url3 && <li><a
                          href={ message.url3 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { message.url3 }
                        </a></li> }
                        { message.url4 && <li><a
                          href={ message.url4 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { message.url4 }
                        </a></li> }
                        { message.url5 && <li><a
                          href={ message.url5 }
                          target="_blank"
                          rel="noopener noreferrer">
                          { message.url5 }
                        </a></li> }
                      </ul>
                    </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > ADJUNTOS </ControlLabel> </Col>
                    <Col md={ 8 }>
                      <ul style={ { paddingLeft: "1%" } }>
                        { message.attachment ?
                          message.attachment.split(',').map(
                            (attached) => {
                              return (
                                <li>
                                  <a
                                    href={ generalRoute + attached }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    { attached }
                                  </a>
                                </li>
                              )
                            }
                          ) :
                          "No tiene adjuntos"
                        }
                      </ul>
                    </Col>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <h4>Datos adicionales</h4>
              <Col md={ 6 }>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel > MENSAJE PROGRAMADO </ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.programmed ? "si" : "no" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel> FECHA DE ENVÍO</ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.date_send && message.date_send.split(" ")[0] }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel> HORA DE ENVÍO</ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.date_send && message.date_send.split(" ")[1] }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel> PERMITE RESPUESTA RÁPIDA</ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.fast_response ? "si" : "no" }</Col>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={ 6 }>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel > REPETIR </ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.repeat ? "si" : "no" }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel> PERIODO</ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.period && periodTimes[message.period - 1].name }</Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }>
                      <ControlLabel> FECHA FIN </ControlLabel>
                    </Col>
                    <Col md={ 8 }> { message.date_end && message.date_end.split(" ")[0] } </Col>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <h4>Destinatarios</h4>
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 12 }>
                  <Table striped hover >
                    <thead>
                      <tr>
                        { thMessageHistoryArray.map((prop, key) => {
                          return key > 0 && <th key={ key }>{ prop }</th>;
                        }) }
                      </tr>
                    </thead>
                    <tbody>
                      { details.map((det) => {
                        return (
                          <tr key={ det.id }>
                            <td>
                              { det.tutor && `${det.tutor.name} ${det.tutor.last_name}` }
                            </td>
                            <td>
                              { det.student && `${det.student.name} ${det.student.last_name}` }
                            </td>
                            <td>{ det.student && det.student.level && det.student.level.name }</td>
                            <td>{ det.student && det.student.grade && det.student.grade.name }</td>
                            <td>{ det.student && det.student.group && det.student.group.name }</td>
                            <td>{ det.state === false ? <span className="label label-danger">No</span> : <span className="label label-success">Si</span> }</td>
                            <td>{ det.fast_response && det.fast_response.fast_response } </td>
                            <td>{ det.created_at }</td>
                            <td>{ det.viewed === null ? "-" : det.viewed }</td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MessageDetail;
