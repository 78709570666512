import React from 'react'
import { Modal, Row, Col, Alert } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class SpinnerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: ''
    };
  }
  async componentDidMount() {
    this.executeRoute();
  }

  executeRoute = async () => {
    const { route, target, actionTitle } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post(route, {
        id: target,
      });
      if (response.status === 200) {
        this.setState({
          isSuccess: true,
          showNotification: true,
          notificationType: "success",
          notificationMessage: actionTitle + " realizado con éxito."
        });
      }
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        this.setState({
          showNotification: true,
          notificationType: "danger",
          notificationMessage: mensajeError500
        });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }
  render() {
    const {
      onClose, show, onFinish, actionTitle, awaitMessage, nameDocument
    } = this.props;

    const {
      isLoading,
      isSuccess,
      notificationMessage,
      notificationType,
      showNotification
    } = this.state;

    return (
      <Modal show={ show } onHide={ onClose } backdrop="static">
        <Modal.Header>
          <Modal.Title>{ actionTitle }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={ 12 }>
              { isLoading ?
                <>
                  <span>Espere, se está { awaitMessage } { nameDocument }</span>
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={ 100 }
                    width={ 100 }
                    style={ { marginLeft: "40%" } }
                  />
                </> :
                (showNotification &&
                  <Alert bsStyle={ notificationType }>
                    <span>
                      { Array.isArray(notificationMessage) ? (
                        <ul>
                          { notificationMessage.map((msg) => {
                            return (<li>{ msg }</li>)
                          }) }
                        </ul>
                      ) : (
                          notificationMessage
                        ) }
                    </span>
                  </Alert>
                )
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="default"
            fill pullRight
            onClick={ onClose }
            style={ { display: (showNotification === true && isSuccess === false) ? 'block' : 'none' } }
          >
            <i className="fa fa-times"></i> Cerrar
          </Button>
          <Button
            bsStyle="success"
            fill pullRight
            onClick={ onFinish }
            style={ { display: isSuccess === true ? 'block' : 'none' } }
          >
            <i className="fa fa-check"></i> Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
} export default SpinnerModal;