import React, { Component } from "react";
import { Grid, Row, Col, } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import TypeMessageList from 'views/messages/TypeMessageList.jsx';
import TypeMessageEdit from 'views/messages/TypeMessageEdit';
import TypeMessageForm from 'views/messages/TypeMesssageForm';
import Button from 'components/CustomButton/CustomButton';

class MessageTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      messageData: {},
      currentPage: 1,
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleEditTypeMessage = (messageData) => {
    this.setState({ key: 1, messageData: messageData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      messageData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <div style={ { marginLeft: '30px', marginRight: '30px' } }>
              <Card
                cardStyle={ {
                  boxShadow: 'none',
                } }
                content={
                  <Grid fluid>
                    { key === 0 &&
                      <Row>
                        <Col md={ 8 } style={ { paddingLeft: "2%" } }>
                          <Button bsStyle="primary" fill type="button" onClick={ () => { this.setState({ key: 2 }) } }>
                            Nuevo tipo de mensaje
                        </Button>
                        </Col>
                      </Row>
                    }
                    {
                      {
                        0: (
                          <TypeMessageList
                            currentPage={ currentPage }
                            editTypeMessage={ this.handleEditTypeMessage }
                            goToNextPage={ this.goToNextPage }
                            goToPreviousPage={ this.goToPreviewsPage }
                          />
                        ),
                        1: <TypeMessageEdit returnToList={ this.handleBackToList } typeData={ messageData } />,
                        2: <TypeMessageForm returnToList={ this.handleBackToList } />,
                      }[key]
                    }
                  </Grid>
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default MessageTypes;

