import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid } from "react-bootstrap";
import MyHomeworkList from "views/tutorViews/MyHomeworkList"
import MyHomeworkEdit from "views/tutorViews/MyHomeworkEdit"


class MyHomework extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      homeworkData: {},
      currentPage: 1,
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };

  handleEditHomework = (homeworkData) => {
    this.setState({ key: 1, homeworkData: homeworkData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }
  render() {
    const {
      key,
      homeworkData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0:
                    <MyHomeworkList
                      editHomework={ this.handleEditHomework }
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                      history={ this.props.history }
                    />
                  ,
                  1: <MyHomeworkEdit
                    returnToList={ this.handleBackToList }
                    homeworkData={ homeworkData } />
                  ,
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default MyHomework;
