import React from 'react'
import {
  Row,
  Col,
  Grid
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';

class PayForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      concept: '',
      mode: '',
      mount: '',
      code: '',
      status: false,
      hasPenalty: false,
      penalty: '',
      showNotification: false,
      notificationMessage: '',
      responseStudent: null,
      studentFound: false
    };
  }

  componentDidMount() {
    this.fetchPenalty()
  }

  fetchPenalty = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/payment/get/penalty');
      this.setState({ penalty: response.data.penalty.penalty_payment });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  checkStudent = async (code) => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/payment/student/search', {
        code
      });
      this.setState({
        responseStudent: response.data.student,
        studentFound: true
      });
      console.log('responde', response);
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            responseStudent: err.response.data.error,
            studentFound: false
          });
        } else {
          this.setState({
            responseStudent: mensajeError500,
            studentFound: false
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      concept,
      mode,
      mount,
      responseStudent,
      hasPenalty,
      status
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      await axios.post('/payment/add', {
        student_id: responseStudent.id,
        concept,
        mount,
        have_penalty: hasPenalty,
        mount_penalty: 0,
        mode,
        status
      });
      this.props.history.replace('/admin/pay/index');
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      hasPenalty,
      penalty,
      status,
      showNotification,
      notificationMessage,
      isSubmitting,
      responseStudent,
      studentFound
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              { !isSubmitting ? (
                <>
                  <form onSubmit={ this.handleSubmit }>
                    <Row>
                      <Col md={ 12 } style={ { padding: "2%" } }>
                        <h4>Registrar un pago</h4>
                        { showNotification &&
                          <ErrorNotification
                            notificationMessage={ notificationMessage }
                            closeNotification={ () => this.setState({ showNotification: false }) }
                          />
                        }
                        <Row style={ { marginTop: "2%" } }>
                          <Col md={ 4 }>
                            <FormGroup>
                              <ControlLabel> Matrícula </ControlLabel>
                              <FormControl type="text" name="code" onChange={ (e) => this.checkStudent(e.target.value) } required />
                              { responseStudent !== null && (
                                studentFound === false ?
                                  <span style={ { color: "#cc3300" } }>{ responseStudent }</span>
                                  :
                                  <span style={ { color: "#006600" } }>{ responseStudent.name } { responseStudent.last_name }</span>
                              ) }
                            </FormGroup>
                          </Col>
                          <Col md={ 2 }></Col>
                          <Col md={ 6 }>
                            <FormGroup>
                              <ControlLabel>Concepto </ControlLabel>
                              <FormControl type="text" name="concept" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row >
                          <Col md={ 4 }>
                            <FormGroup>
                              <ControlLabel> Modo pago </ControlLabel>
                              <FormControl type="text" name="mode" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                          </Col>
                          <Col md={ 2 }></Col>
                          <Col md={ 6 }>
                            <FormGroup>
                              <ControlLabel>Monto </ControlLabel>
                              <FormControl type="number" name="mount" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                            <Checkbox
                              number="0"
                              checked={ hasPenalty }
                              label={ `agregar penalidad(${penalty}%)` }
                              onChange={ () => this.setState({ hasPenalty: !this.state.hasPenalty }) }
                            />
                            <Checkbox
                              number="1"
                              checked={ status }
                              label="pagado"
                              onChange={ () => this.setState({ status: !this.state.status }) }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={ 12 } style={ { marginLeft: "0px" } }>
                            <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                              <i className="fa fa-save"></i> Guardar
                            </Button>
                            <Button bsStyle="danger" pullRight fill type="button" onClick={ () => this.props.history.replace("/admin/pay/index") } style={ styles.saveButton }>
                              <i className="fa fa-ban"></i> Cancelar
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                </>
              ) : (
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={ 100 }
                    width={ 100 }
                    style={ { display: 'flex', justifyContent: 'center' } }
                  />
                ) }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default PayForm