module.exports = {
    saveButton: {
        marginTop: "5%",
        marginRight: "2%",
        marginLeft: "1%"
    },
    massive: {
        marginTop: "1%",
        marginLeft: "1.5%",
        width: "72.25%",
        marginBottom: "1%"
    },
    header: {
        marginLeft: "1.5%"
    },
    bodyInfo: {
        marginLeft: "5%"
    },
    headerLeft: {
        marginLeft: "-3%"
    },
    destinity: {
        marginLeft: "2%",
        width: "97%"
    },
    data: {
        marginLeft: "2%"
    },
    iconStyle: {
        width: "60px",
        height: "60px",
        border: "black",
        borderStyle: "dotted",
        borderRadius: "7px",
        margin: "5px"
    },
    iconSelect: {
        width: "65px",
        height: "65px",
        border: "blue",
        borderStyle: "solid",
        borderRadius: "7px",
        margin: "5px",
    },
    iconList: {
        width: "50px",
        height: "50px",
    }
}