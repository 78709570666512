import React from 'react'
import { Modal, Row, Col, Table } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import axios from 'components/axios';
import styles from './styles.js'
import Loader from 'react-loader-spinner'

class ScoreReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      cycle: null,
      student: null,
      periodMax: 0,
      institution: null,
      final: null,
      encodedBase: '',
      urlPdf: ''
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { scoreId } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post(`score/one/${scoreId}`);
      const response1 = await axios.post(`/score/print_pdf`, {
        score_id: scoreId
      });
      this.setState({
        periodMax: response.data.score.period_max,
        final: response.data.score.final,
        courses: response.data.score.details,
        cycle: response.data.score.cycle,
        institution: response.data.score.institution,
        student: response.data.score.student,
        urlPdf: response1.data.file1
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getHeader = (num) => {
    var rows = [];
    for (var i = 1; i < num + 1; i++) {
      rows.push(<th key={ 'th' + i } style={ { textAlign: "center" } }>{ i }°</th>);
    }
    return rows;
  }
  getRows = (num, detail) => {
    var rows = [];
    for (var i = 1; i < num + 1; i++) {
      const CustomTag = `value${i}`;
      rows.push(<td key={ 'td' + i } style={ { textAlign: "center" } }>{ detail[CustomTag] }</td>);
    }
    return rows;
  }

  render() {
    const {
      onClose, open, logo
    } = this.props;
    const {
      courses,
      cycle,
      student,
      periodMax,
      institution,
      final,
      isLoading,
      urlPdf
    } = this.state;
    return (
      <Modal show={ open } onHide={ onClose } backdrop="static" >
        <Modal.Header closeButton>
          <Modal.Title>Boleta de evaluación </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={ 12 } style={ { padding: "2%" } }>
              { !isLoading ?
                <div id="reportPDF">
                  <div className="row">
                    <div className="col-md-4">
                      <img src={ logo } alt="logo_image" style={ { width: "90%" } } />
                    </div>
                    <div className="col-md-6" style={ { marginLeft: "5%", marginTop: "10%" } }>
                      <span style={ { textAlign: "center", fontSize: "large" } }><b>Boleta de evaluación</b></span>
                      <br /><span style={ { textAlign: "center" } }>Ciclo escolar: { cycle && cycle.name } </span>
                    </div>
                    <div className="col-md-12" style={ { marginTop: "2%" } }>
                      <div className="col-md-4" style={ { textAlign: "center" } }>{ student && student.last_name }</div>
                      <div className="col-md-4" style={ { textAlign: "center" } }>{ student && student.name }</div>
                      <div className="col-md-4" style={ { textAlign: "center" } }>{ student && student.code }</div>
                    </div>
                    <div className="col-md-12"  >
                      <div className="col-md-4" style={ styles.data }>Apellidos</div>
                      <div className="col-md-4" style={ styles.data }>Nombres</div>
                      <div className="col-md-4" style={ styles.data }>Matrícula</div>
                    </div>
                    <div className="col-md-12" style={ { marginTop: "1%" } }>
                      <div className="col-md-3" style={ { textAlign: "center", fontSize: "small" } }>{ institution && institution.name }</div>
                      <div className="col-md-3" style={ { textAlign: "center", paddingTop: "2%" } }>{ student && student.level && student.level.name }</div>
                      <div className="col-md-3" style={ { textAlign: "center", paddingTop: "2%" } }>{ student && student.grade && student.grade.name }</div>
                      <div className="col-md-3" style={ { textAlign: "center", paddingTop: "2%" } }>{ student && student.group && student.group.name }</div>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-3" style={ styles.data }>Escuela</div>
                      <div className="col-md-3" style={ styles.data }>Nivel</div>
                      <div className="col-md-3" style={ styles.data }>Grado</div>
                      <div className="col-md-3" style={ styles.data }>Grupo</div>
                    </div>
                  </div>
                  <div className="row" style={ { padding: "3%" } }>
                    <Table bordered striped >
                      <thead >
                        <tr>
                          <th rowSpan="2" style={ { textAlign: "center" } }>Materia</th>
                          <th colSpan={ periodMax } style={ { textAlign: "center" } }>Periodos</th>
                          <th rowSpan="2" style={ { textAlign: "center" } }>Promedio final</th>
                        </tr>
                        <tr>
                          { this.getHeader(periodMax).map((score) => {
                            return (score);
                          })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        { courses.map((course) => {
                          return (
                            <tr key={ course.id }>
                              <td>{ course.course_name }</td>
                              { this.getRows(periodMax, course).map((score) => {
                                return (score);
                              }) }
                              <td>{ course.value_final ? course.value_final : "-" }</td>
                            </tr>

                          );
                        }) }
                      </tbody>
                    </Table>
                    <div className="col-md-12" style={ { marginTop: "2%" } }>
                      <div className="col-md-6" style={ styles.data }>Promedio final del curso:</div>
                      <div className="col-md-6" style={ { textAlign: "center" } }> { final ? final : "-" } </div>
                    </div>
                  </div>
                </div>
                :
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={ 100 }
                  width={ 100 }
                  style={ { marginLeft: "40%" } }
                />
              }

            </Col>

            <Col md={ 12 } style={ { marginLeft: "35%", marginTop: "2%" } }>
              <a
                href={ urlPdf !== '' && urlPdf }
                target="_blank"
                rel="noopener noreferrer">
                <Button bsStyle="primary" fill><i className="fa fa-file-pdf-o"></i>Descargar en PDF</Button>
              </a>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
export default ScoreReportModal;