

import React from 'react'
import { Row, Col, FormGroup, ControlLabel, FormControl, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class ScannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      email: '',
      user: '',
      newPass: '',
      showNotification: false,
      notificationMessage: '',
    };
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      lastname,
      email,
      user,
      newPass
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      await axios.post('/recorder/add', {
        firstname: name,
        lastname: lastname,
        email,
        username: user,
        password: newPass
      });
      this.props.history.replace('/admin/attendance/list/scan');
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      isSubmitting
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              { !isSubmitting ? (
                <>
                  <form onSubmit={ this.handleSubmit }>
                    <Row>
                      <Col md={ 12 } style={ { padding: "2%" } }>
                        <h4>Registrar un scanner</h4>
                        { showNotification &&
                          <ErrorNotification
                            notificationMessage={ notificationMessage }
                            closeNotification={ () => this.setState({ showNotification: false }) }
                          />
                        }
                        <Row style={ { marginTop: "2%" } }>
                          <Col md={ 4 }>
                            <FormGroup>
                              <ControlLabel> NOMBRES </ControlLabel>
                              <FormControl type="text" name="name" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                          </Col>
                          <Col md={ 2 }></Col>
                          <Col md={ 6 }>
                            <FormGroup>
                              <ControlLabel> CORREO ELECTRÓNICO </ControlLabel>
                              <FormControl type="email" name="email" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={ 4 }>
                            <FormGroup>
                              <ControlLabel> APELLIDOS </ControlLabel>
                              <FormControl type="text" name="lastname" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                          </Col>
                          <Col md={ 2 }></Col>
                          <Col md={ 6 }>
                            <FormGroup>
                              <ControlLabel> USUARIO </ControlLabel>
                              <FormControl type="text" name="user" onChange={ e => this.onChange(e) } required />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={ 6 }></Col>
                          <Col md={ 6 }>
                            <FormGroup>
                              <ControlLabel> CONTRASEÑA </ControlLabel>
                              <FormControl type="text" name="newPass" onChange={ e => this.onChange(e) } />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={ 12 } style={ { marginLeft: "0px" } }>
                            <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                              <i className="fa fa-save"></i> Guardar
                                </Button>
                            <Button bsStyle="danger" pullRight fill type="button" onClick={ () => this.props.history.replace("/admin/attendance/list/scan") } style={ styles.saveButton }>
                              <i className="fa fa-ban"></i> Cancelar
                                </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                </>
              ) : (
                  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={ 100 }
                    width={ 100 }
                    style={ { display: 'flex', justifyContent: 'center' } }
                  />
                ) }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default ScannerForm