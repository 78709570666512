import React from 'react'
import { Modal } from "react-bootstrap";
const ShowAttachedModal = ({ onClose, show, title, attacheds, type, route }) => {

  return (
    <Modal show={ show } onHide={ onClose }>
      <Modal.Header closeButton>
        <Modal.Title>{ title }</Modal.Title>
      </Modal.Header>
      <Modal.Body style={ { wordWrap: "break-word" } }>
        <ul>
          {
            {
              "urls":
                <>
                  {
                    attacheds.url1 && attacheds.url1 !== "" &&
                    <li key={ attacheds.url1 }>
                      <a
                        href={ attacheds.url1 }
                        target="_blank"
                        rel="noopener noreferrer">
                        { attacheds.url1 }
                      </a>
                    </li>
                  }
                  {
                    attacheds.url2 && attacheds.url2 !== "" &&
                    <li key={ attacheds.url2 }>
                      <a
                        href={ attacheds.url2 }
                        target="_blank"
                        rel="noopener noreferrer">
                        { attacheds.url2 }
                      </a>
                    </li>
                  }
                  {
                    attacheds.url3 && attacheds.url3 !== "" &&
                    <li key={ attacheds.url3 }>
                      <a
                        href={ attacheds.url3 }
                        target="_blank"
                        rel="noopener noreferrer">
                        { attacheds.url3 }
                      </a>
                    </li>
                  }
                  {
                    attacheds.url4 && attacheds.url4 !== "" &&
                    <li key={ attacheds.url4 }>
                      <a
                        href={ attacheds.url4 }
                        target="_blank"
                        rel="noopener noreferrer">
                        { attacheds.url4 }
                      </a>
                    </li>
                  }
                  {
                    attacheds.url5 && attacheds.url5 !== "" &&
                    <li key={ attacheds.url5 }>
                      <a
                        href={ attacheds.url5 }
                        target="_blank"
                        rel="noopener noreferrer">
                        { attacheds.url5 }
                      </a>
                    </li>
                  }
                </>,
              "files":
                attacheds.files && attacheds.files.split(',').map(
                  (att) => {
                    return (
                      <li key={ att }>
                        <a
                          href={ route + att }
                          target="_blank"
                          rel="noopener noreferrer">
                          { att }
                        </a>
                      </li>
                    )
                  }
                ),
              "attachment":
                attacheds.attachment && attacheds.attachment.split(',').map(
                  (att) => {
                    return (
                      <li key={ att }>
                        <a
                          href={ route + att }
                          target="_blank"
                          rel="noopener noreferrer">
                          { att }
                        </a>
                      </li>
                    )
                  }
                ),
              "docs":
                attacheds.docs && attacheds.docs.split(',').map(
                  (att) => {
                    return (
                      <li key={ att }>
                        <a
                          href={ route + att }
                          target="_blank"
                          rel="noopener noreferrer">
                          { att }
                        </a>
                      </li>
                    )
                  }
                ),
              "pics":
                attacheds.pics && attacheds.pics.split(',').map(
                  (att, index) => {
                    return (
                      <li key={ index }>
                        <a
                          href={ route + att }
                          target="_blank"
                          rel="noopener noreferrer">
                          { att }
                        </a>
                      </li>
                    )
                  }
                ),
            }[type]
          }
        </ul>
      </Modal.Body>
    </Modal >
  );
};
export default ShowAttachedModal;