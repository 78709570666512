import React, { Component } from "react";
import { Row, Col, Table, FormGroup, ControlLabel, } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import { homeworkStatus } from "variables/Variables.jsx";
import { generalRoute } from "variables/DomainRoutes.jsx";
import Loader from 'react-loader-spinner'
import ShowAttachedModal from "components/Modal/ShowAttachedModal";

class HomeworkDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      message: {},
      details: [],
      showAttachedModal: false,
      attacheds: '',
      titleAttached: '',
      typeShowModal: ''
    };
  }
  async componentDidMount() {
    this.fetchHomeworkDetail();
  }

  fetchHomeworkDetail = async () => {
    this.setState({ isLoading: true });
    const { homeworkData: { id } } = this.props;
    try {
      const response = await axios.post(`homework/details/${id}`);
      this.setState({ details: response.data.homeworks_students });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleShowModal = (homework, type, num) => {
    if (num > 0) {
      if (type === "pics") {
        this.setState({
          showAttachedModal: true,
          attacheds: homework,
          titleAttached: 'Se adjuntaron las siguientes imágenes',
          typeShowModal: type
        });
      } else {
        this.setState({
          showAttachedModal: true,
          attacheds: homework,
          titleAttached: 'Se adjuntaron los siguientes archivos',
          typeShowModal: type
        });
      }
    }
  }

  renderBudge = (status) => {
    switch (status) {
      case 0:
        return "label label-default";
      case 1:
        return "label label-warning";
      case 2:
        return "label label-success";
      case 3:
        return "label label-danger";
      default:
        return "label label-default";
    }
  }

  render() {
    const {
      returnToList,
      reviewHomework,
      homeworkData
    } = this.props;
    const {
      isLoading,
      showAttachedModal,
      attacheds,
      titleAttached,
      typeShowModal,
      details
    } = this.state;
    return !isLoading ? (
      <>
        <Row style={ { padding: "2%" } }>
          <h4>Detalle de la tarea</h4>
          <Row style={ { marginTop: "1%" } }>
            <Col md={ 6 }>
              <FormGroup>
                <ControlLabel> MATERIA </ControlLabel>
                <span style={ styles.data }>{ homeworkData.course.name }</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel> NIVEL </ControlLabel>
                <span style={ styles.data }>{ homeworkData.level.name }</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel> GRADO </ControlLabel>
                <span style={ styles.data }>{ homeworkData.grade.name }</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel> GRUPO </ControlLabel>
                <span style={ styles.data }>{ homeworkData.group.name }</span>
              </FormGroup>
            </Col>
            <Col md={ 6 }>
              <FormGroup>
                <ControlLabel> MENSAJE DE TAREA </ControlLabel>
                <span style={ styles.data }
                  dangerouslySetInnerHTML={ { __html: homeworkData.msg } } />
              </FormGroup>
            </Col>

            <Col md={ 12 }>
              {
                showAttachedModal &&
                <ShowAttachedModal
                  title={ titleAttached }
                  attacheds={ attacheds }
                  onClose={ () => this.setState({ showAttachedModal: false }) }
                  show={ showAttachedModal }
                  route={ generalRoute }
                  type={ typeShowModal }
                />
              }
              <h4>Estudiantes a los que se envió la tarea</h4>
              <Table striped hover >
                <thead>
                  <tr>
                    <th>alumno</th>
                    <th>imágenes</th>
                    <th>documentos</th>
                    <th>mensaje</th>
                    <th>estado</th>
                    <th>Observación</th>
                    <th>creado</th>
                    <th>acciones</th>
                  </tr>
                </thead>
                <tbody>
                  { details.map((det) => {
                    const statusAsString = typeof homeworkStatus[det.status] !== 'undefined' ? homeworkStatus[det.status] : '';
                    return (
                      <tr key={ det.id }>
                        <td>{ det.student && `${det.student.name} ${det.student.last_name}` }</td>
                        <td>
                          <span className="label label-info"
                            onClick={ () => this.handleShowModal(det, "pics", det.pics ? det.pics.split(',').length : 0) }
                          >
                            <i className="fa fa-image"></i>
                            {
                              det.pics ? det.pics.split(',').length : 0
                            }
                          </span>
                        </td>
                        <td>
                          <span className="label label-primary"
                            onClick={ () => this.handleShowModal(det, "docs", det.docs ? det.docs.split(',').length : 0) }>
                            <i className="fa fa-paperclip"></i>
                            {
                              det.docs ? det.docs.split(',').length : 0
                            }
                          </span>
                        </td>
                        <td>{ det.msg }</td>
                        <td>
                          <span className={ this.renderBudge(det.status) }>
                            { statusAsString }
                          </span>
                        </td>
                        <td>{ det.obs }</td>
                        <td>{ det.created_at }</td>
                        <td>
                          <Button
                            bsStyle="success"
                            bsSize="sm"
                            fill title="Revisar"
                            style={ { display: det.status === 0 ? "none" : "block" } }
                            onClick={ () => reviewHomework(det, homeworkData) }
                          >
                            <i className="fa fa-edit"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  }) }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col md={ 12 } style={ { marginLeft: "0px" } }>
            <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
              <i className="fa fa-chevron-left"></i> Regresar
                </Button>
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default HomeworkDetail;
