import React from 'react'
import { Modal, FormControl, Row, Col, FormGroup, Table } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner';

class AddExtraStudentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentNameRequest: '',
      studentId: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: '',
      options: [],
      isLoadingSearch: 0,
      isAdding: false,
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSearch = async () => {
    const { studentNameRequest } = this.state;
    this.setState({ isLoadingSearch: 1 });
    try {
      const response = await axios.post('/extracurricular/search/student', {
        name: studentNameRequest,
      });
      this.setState({ options: response.data.student });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoadingSearch: 2 });
    }
  }

  handleAdd = async (e) => {
    this.setState({ isAdding: true });
    try {
      await axios.post('extracurricular/add/student/', {
        extracurricular_id: this.props.extraId,
        student_id: e.id
      });
      this.setState({
        notificationType: "success",
        notificationMessage: "Estudiante " + e.name + " " + e.last_name + " agregado.",
        showNotification: true
      })
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isAdding: false });
    } finally {
      this.setState({ isAdding: false });
    }

  }

  render() {
    const {
      onClose,
      show,
    } = this.props;
    const {
      showNotification,
      notificationMessage,
      notificationType,
      options,
      isLoadingSearch,
      isAdding
    } = this.state;
    return (
      <Modal show={ show } onHide={ onClose } backdrop="static">
        <Modal.Header closeButton >
          <Modal.Title>Agregar estudiante</Modal.Title>
        </Modal.Header>
        <Modal.Body style={ { wordWrap: "break-word" } }>

          <Row>
            <Col md={ 10 }>
              <FormGroup>
                <Col md={ 6 }>
                  <FormControl placeholder="Nombre del estudiante" name="studentNameRequest" onChange={ e => this.onChange(e) } />
                </Col>
                <Col md={ 4 }>
                  <Button bsStyle="success" fill block onClick={ () => this.handleSearch() }>Buscar</Button>
                </Col>
              </FormGroup>

            </Col>

            { showNotification &&
              <Col md={ 12 }>
                <ModalNotification
                  type={ notificationType }
                  notificationMessage={ notificationMessage }
                  closeNotification={ () => this.setState({ showNotification: false }) }
                />
              </Col>
            }
          </Row>
          { isLoadingSearch === 1 || isAdding === true ?
            <Loader
              type="Rings"
              color="#00BFFF"
              height={ 100 }
              width={ 100 }
              style={ { display: 'flex', justifyContent: 'center' } }
            />
            :
            (
              (isLoadingSearch === 2 && options.length === 0 ?
                <Col style={ { margin: "2%" } }>
                  <i className="fa fa-times"></i>
                  No se encontraron estudiantes con ese nombre o apellido.
                </Col>
                :
                (options.length > 0 &&
                  <Table striped hover style={ { marginTop: "2%" } }>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Matricula</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      { options.map((extra) => {
                        return (
                          <tr key={ extra.id }>
                            <td>{ extra.name }</td>
                            <td>{ extra.last_name }</td>
                            <td>{ extra.code }</td>
                            <td>
                              <Button
                                bsStyle="primary"
                                fill
                                onClick={ () => this.handleAdd(extra) }
                              >
                                <i className="fa fa-plus"></i> Agregar
                        </Button>
                            </td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                )
              )
            )
          }
        </Modal.Body>
        <Modal.Footer >
          <Button bsStyle="danger" fill style={ styles.saveButton } onClick={ onClose }>
            <i className="fa fa-times"></i> Cerrar
            </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}
export default AddExtraStudentModal