import React, { Component } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import { FormGroup, ControlLabel } from "react-bootstrap";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'

class HeaderType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      isLoading: false,
      showNotification: false,
      notificationMessage: ''
    };
  }
  async componentDidMount() {
    this.fetchTypes();
  }

  fetchTypes = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/type/list', {
        current_page: 1,
      });
      const newData = response.data.Types.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ types: newData })
      if (response.data.Types.data.length === 0) {
        this.setState({
          showNotification: true,
          notificationMessage: "No tiene TIPOS DE MENSAJE configurados."
        });
      }
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onChange(e) {
    this.props.onSelectTypeId(e.value);
  }

  render() {
    const {
      isLoading,
      types,
      showNotification,
      notificationMessage
    } = this.state;
    return (
      <Row>
        <Col md={ 8 }>
          <FormGroup>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <ControlLabel>
              TIPO DE MENSAJE
            </ControlLabel>
            { !isLoading ? (
              <Select options={ types }
                name="typeId" className="basic-single"
                classNamePrefix="select"
                onChange={ e => this.onChange(e) }
                placeholder="Seleccione un tipo" />
            ) : (
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={ 20 }
                  width={ 20 }
                  style={ { display: 'flex', justifyContent: 'center' } }
                />
              ) }
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default HeaderType;
