import React, { Component } from "react";
import {
    Row,
    Col
} from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'

class ButtonsConfirmCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <Row>
                <Col md={ 12 } style={ { marginLeft: "0px" } } >
                    <Button bsStyle="success" bsSize="lg" pullRight fill type="submit" style={ styles.saveButton }>
                        <i className="fa fa-save"></i> Enviar
                    </Button>
                    <Button bsStyle="danger" bsSize="lg" pullRight fill type="button" style={ styles.saveButton } onClick={ () => this.props.history.replace("/admin/message/index") }>
                        <i className="fa fa-chevron-left"></i> Cancelar
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default ButtonsConfirmCancel;
