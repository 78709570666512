import React from 'react'
import { Grid } from "react-bootstrap";
import StudentList from './students/StudentList';
import StudentForm from './students/StudentForm';
import StudentDetail from './students/StudentDetail';
import StudentEdit from './students/StudentEdit';
import { Card } from "components/Card/Card.jsx";

class StudentMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      studentData: {},
      currentPage: 1
    };
  }

  handleBackToList = (currentPage) => {
    this.setState({ key: 0, currentPage: currentPage });
  };

  handleDetailStudent = (studentData, currentPage) => {
    this.setState({ key: 2, studentData: studentData, currentPage: currentPage });
  };

  handleEditStudent = (studentData, currentPage) => {
    this.setState({ key: 3, studentData: studentData, currentPage: currentPage });
  };

  handleNewChild = () => {
    this.setState({ key: 4 });
  };
  handleNewStudent = () => {
    this.setState({ key: 1 });
  };

  render() {
    const {
      key,
      studentData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: <StudentList
                    newStudent={ this.handleNewStudent }
                    detailStudent={ this.handleDetailStudent }
                    editStudent={ this.handleEditStudent }
                    newChild={ this.handleNewChild }
                    currentPage={ currentPage }
                  />,
                  1: <StudentForm returnToList={ this.handleBackToList } />,
                  2: <StudentDetail returnToList={ this.handleBackToList } studentData={ studentData } currentPage={ currentPage } />,
                  3: <StudentEdit returnToList={ this.handleBackToList } studentData={ studentData } currentPage={ currentPage } />
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default StudentMenu