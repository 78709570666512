import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class ScannerEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      email: '',
      username: '',
      newPass: '',

      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
    };
  }
  componentDidMount() {
    const { scannerData: { firstname, lastname, email, username } } = this.props;
    this.setState({
      name: firstname,
      lastname: lastname,
      email,
      username
    })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { scannerData: { id } } = this.props;
    const {
      name,
      lastname,
      email,
      username,
      newPass
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      if (newPass != '') {
        await axios.post(`recorder/edit/${id}`, {
          firstname: name,
          lastname: lastname,
          email,
          username,
          password: newPass
        });
      } else {
        await axios.post(`recorder/edit/${id}`, {
          firstname: name,
          lastname: lastname,
          email,
          username,
        });
      }

      this.props.returnToList();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }


  render() {
    const {
      returnToList,
    } = this.props;
    const {
      name,
      lastname,
      email,
      username,
      isSubmitting,
      showNotification,
      notificationMessage,
    } = this.state;
    return !isSubmitting ? (
      <>
        <form onSubmit={ this.handleSubmit }>
          <Row>
            <Col md={ 12 } style={ { padding: "2%" } }>
              <h4>Editar un scanner</h4>
              { showNotification &&
                <ErrorNotification
                  notificationMessage={ notificationMessage }
                  closeNotification={ () => this.setState({ showNotification: false }) }
                />
              }
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 4 }>
                  <FormGroup>
                    <ControlLabel> NOMBRES </ControlLabel>
                    <FormControl type="text" name="name" value={ name } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <ControlLabel> CORREO ELECTRÓNICO </ControlLabel>
                    <FormControl type="email" name="email" value={ email } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={ 4 }>
                  <FormGroup>
                    <ControlLabel> APELLIDOS </ControlLabel>
                    <FormControl type="text" name="lastname" value={ lastname } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <ControlLabel> USUARIO </ControlLabel>
                    <FormControl type="text" name="username" value={ username } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={ 6 }></Col>
                <Col md={ 6 }>
                  <FormGroup>
                    <ControlLabel> NUEVA CONTRASEÑA </ControlLabel>
                    <FormControl type="text" name="newPass" onChange={ e => this.onChange(e) } />
                    <span>Si se desea cambiar contraseña, llenar este campo. De lo contrario dejarlo en blanco.</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { marginLeft: "0px" } }>
                  <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                    <i className="fa fa-save"></i> Guardar
                  </Button>
                  <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                    <i className="fa fa-chevron-left"></i> Regresar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default ScannerEdit