
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup, ControlLabel, FormControl,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import styles from './styles'
import imageLogin from "assets/img/login.jpg";
import logo from "assets/img/logo.png";
import axios from 'components/axios';
import Button from 'components/CustomButton/CustomButton';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      showNotification: false,
      notificationMessage: '',
      isSubmitting: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.setState({ isSubmitting: true });
    try {
      const response = await axios.post('user/mail-new-password', {
        user_email: email,
      });
      if (response.status === 200) {
        this.setState({
          showNotification: true,
          notificationType: "success",
          notificationMessage: "Gracias te enviaremos un mail con las instrucciones."
        });
      }
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      email,
      notificationMessage,
      notificationType,
      showNotification,
      isSubmitting
    } = this.state;
    return (
      <form onSubmit={ this.handleSubmit } className="content">
        <Grid fluid>
          <Row >
            <Col md={ 5 } style={ { marginTop: "1%" } }>
              <Card
                content={
                  <>
                    <Row >
                      <Col md={ 1 }> </Col>
                      <Col md={ 10 } style={ { textAlign: "center", marginTop: "15%" } }>
                        <img
                          src={ logo }
                          width="200"
                          alt=""
                        />
                      </Col>
                      <Col md={ 1 }></Col>
                    </Row>
                    { !isSubmitting ? (
                      <>
                        <Row>
                          <Col md={ 8 }>
                            <Button bsStyle="info" simple style={ { marginTop: "10%" } } onClick={ () => this.props.history.replace('/login') }>
                              Regresar al login de director
                              </Button>
                          </Col>
                          <Col md={ 4 }></Col>
                        </Row>
                        <Row style={ { paddingBottom: "27%", textAlign: "center" } }>
                          <FormGroup style={ styles.marginTop2 }>
                            <Col md={ 12 }>
                              <ControlLabel>
                                <h4><b>
                                  Ingresa tu correo
                              </b></h4>
                              y te enviaremos instrucciones para reiniciar tu contraseña.
                            </ControlLabel>
                              <FormControl required style={ { marginTop: "2%" } } value={ email } placeholder="Correo Electrónico" onChange={ (e) => this.setState({ email: e.target.value }) } type="email" name="email" />
                            </Col>
                          </FormGroup>


                          <Col md={ 2 }></Col>
                          <Col md={ 8 } style={ { marginTop: "8%" } }>
                            { showNotification &&
                              <Alert bsStyle={ notificationType }>
                                <button type="button" aria-hidden="true" className="close" onClick={ () => this.setState({ showNotification: false }) }>
                                  &#x2715;
                                </button>
                                <span>{ notificationMessage }</span>
                              </Alert>
                            }
                            <Button bsStyle="info" fill block type="submit" >
                              Enviar instrucciones
                            </Button>
                          </Col>
                          <Col md={ 2 }></Col>
                        </Row>
                      </>
                    ) : (
                        <Loader
                          style={ { marginLeft: "35%", marginTop: "10%" } }
                          type="Rings"
                          color="#00BFFF"
                          height={ 200 }
                          width={ 200 }
                        />
                      )
                    }
                  </>
                }
              />
            </Col>
            <Col md={ 7 } style={ { padding: "0px" } }>
              <img
                src={ imageLogin }
                width="100%"
                height="945"
                alt=""
              />
            </Col>
          </Row>
        </Grid>
      </form>
    );
  }
}

export default ForgotPassword;
