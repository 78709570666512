

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'

class PayDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      returnToList,
      payData
    } = this.props;
    return (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row>
              <Col md={ 4 }>
                <h4>Datos del Pago</h4>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Código</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.code } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Concepto</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.concept } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Matrícula</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.student && payData.student.code } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Tutor</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.tutor && `${payData.tutor.name} ${payData.tutor.last_name}` } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Estudiante</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.student && `${payData.student.name} ${payData.student.last_name}` } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Tipo</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.kind === 1 ? "Manual" : "CSV" } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Modo de pago</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.mode } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Monto</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.mount } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Penalidad</ControlLabel> </Col>
                    <Col md={ 8 }> { payData.mount_penalty } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Monto total</ControlLabel> </Col>
                    <Col md={ 8 }> { parseFloat(payData.mount) + parseFloat(payData.mount_penalty) } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Estado</ControlLabel> </Col>
                    <Col md={ 8 }>
                      { payData.status === true ?
                        <span className="label label-success">Pagado</span>
                        :
                        <span className="label label-warning">En espera</span>
                      }
                    </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Enviado </ControlLabel> </Col>
                    <Col md={ 8 }> { payData.created_at } </Col>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
export default PayDetail