import React from 'react'
import { Modal } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'

const ConfirmActionModal = (
  { onClose, show, actionTitle, actionBody, onConfirm }
) => {
  return (
    <Modal show={ show } onHide={ onClose }>
      <Modal.Header closeButton >
        <Modal.Title>Confirmar { actionTitle }</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        { actionBody }
      </Modal.Body>
      <Modal.Footer >
        <Button bsStyle="default" fill type="button" style={ styles.saveButton } onClick={ onClose }>
          Cancelar
        </Button>
        <Button bsStyle="danger" fill type="button" style={ styles.saveButton } onClick={ onConfirm }>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal >
  );
};
export default ConfirmActionModal;