import React, { Component } from "react";
import {
  Row,
  Col,
  ControlLabel,
  FormGroup,
  Alert
} from "react-bootstrap";

import { mensajeError500 } from "variables/Variables.jsx";
import { generalRoute } from "variables/DomainRoutes.jsx";
import styles from './styles'
import { Card } from "components/Card/Card.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import AttachedItem from "views/messages/components/AttachedItem";
import AttachedImage from "views/tutorViews/AttachedImage";
import AddButton from "views/messages/components/AddButton";
import LoadingModal from "components/Modal/LoadingModal";
class MyHomeworkEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      image5: '',
      attached1: '',
      attached2: '',
      attached3: '',
      attached4: '',
      attached5: '',
      isSubmitting: false,
      isLoading: false,
      groups: [],
      groupId: '',
      homework: {},
      attached: 0,
      attachedData: [],
      images: 0,
      imagesData: [],
      showLoadingModal: false
    };
  }

  componentDidMount() {
    this.setState({ homework: this.props.homeworkData });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  showModal = () => {
    this.setState({ showLoadingModal: true })
  }

  closeModal = () => {
    this.setState({ showLoadingModal: false })
  }

  _add = () => {
    let { attachedData } = this.state;
    let newData = [
      ...attachedData,
      {
        name: "attached",
      }
    ]
    this.setState(prevState => ({
      attached: prevState.attached + 1,
      attachedData: newData
    }))
  }

  _addImage = () => {
    let { imagesData } = this.state;
    let newData = [
      ...imagesData,
      {
        name: "image",
      }
    ]
    this.setState(prevState => ({
      images: prevState.images + 1,
      imagesData: newData
    }))
  }

  _remove(position) {
    let { attachedData } = this.state;
    let newData = [
      ...attachedData.slice(0, position),
      ...attachedData.slice(position + 1),
    ]
    this.setState(prevState => ({
      attached: prevState.attached - 1,
      attachedData: newData,
      [`attached${position + 1}`]: ''
    }))
  }

  _removeImage(position) {
    let { imagesData } = this.state;
    let newData = [
      ...imagesData.slice(0, position),
      ...imagesData.slice(position + 1),
    ]
    this.setState(prevState => ({
      images: prevState.images - 1,
      imagesData: newData,
      [`images${position + 1}`]: ''
    }))
  }

  joinAttached = () => {
    const {
      attached1,
      attached2,
      attached3,
      attached4,
      attached5,
    } = this.state;
    let attached_str = "";
    let attacheds = [];
    attacheds.push(attached1)
    attacheds.push(attached2)
    attacheds.push(attached3)
    attacheds.push(attached4)
    attacheds.push(attached5)
    for (let index = 0; index < 5; index++) {
      if (attacheds[index] !== "") {
        if (index === 0) {
          attached_str = attached_str + attacheds[index]
        } else {
          attached_str = attached_str + "," + attacheds[index]
        }
      }
    }
    return attached_str
  }
  joinImages = () => {
    const {
      image1,
      image2,
      image3,
      image4,
      image5,
    } = this.state;
    let image_str = "";
    let images = [];
    images.push(image1)
    images.push(image2)
    images.push(image3)
    images.push(image4)
    images.push(image5)
    for (let index = 0; index < 5; index++) {
      if (images[index] !== "") {
        if (index === 0) {
          image_str = image_str + images[index]
        } else {
          image_str = image_str + "," + images[index]
        }
      }
    }
    return image_str
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      msg
    } = this.state;
    this.setState({ isSubmitting: true });
    try {
      await axios.post('homework_student/one/' + this.props.homeworkData.id, {
        msg,
        pics: this.joinImages(),
        docs: this.joinAttached()
      });
      this.props.returnToList()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      homeworkData,
      returnToList
    } = this.props;
    const {
      showNotification,
      notificationMessage,
      attached,
      showLoadingModal,
      images
    } = this.state
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row style={ { paddingLeft: "30px" } }>
                <h4>Responder tarea</h4>
                {
                  homeworkData.status !== 0 &&
                  <Col md={ 10 } >
                    <Alert bsStyle="info" >
                      <span>Ya existe una respuesta para esta tarea, si envias otra respuesta esta reemplazará  a la anterior.</span>
                    </Alert>
                  </Col>
                }
                <LoadingModal
                  show={ showLoadingModal }
                  text="Espere, se está adjuntando el archivo seleccionado ..."
                />

                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
                <Col md={ 8 } >
                  <Col md={ 12 }>
                    <ControlLabel > MATERIA </ControlLabel><br />
                    <span> { homeworkData.homework.course.name } </span>
                  </Col>
                  <Col md={ 12 }>
                    <ControlLabel > ALUMNO </ControlLabel><br />
                    <span>{ homeworkData.student.name } { homeworkData.student.last_name }</span>
                  </Col>
                  <Col md={ 12 }>
                    <ControlLabel > MENSAJE DE LA TAREA </ControlLabel><br />
                    <span style={ styles.data }
                      dangerouslySetInnerHTML={ { __html: homeworkData.homework.msg } } />
                  </Col>
                  <Col md={ 12 }>
                    <ControlLabel > ARCHIVOS DE LA TAREA </ControlLabel><br />
                    <Col md={ 6 }>
                      <FormGroup>
                        <Col md={ 12 }>
                          <Col md={ 4 }> <ControlLabel > URLS </ControlLabel> </Col>
                          <Col md={ 8 }>
                            { (homeworkData.homework.url1 === null || homeworkData.homework.url1 === "") ?
                              "No tiene urls adjuntas" :
                              <ul style={ { paddingLeft: "1%" } }>
                                { <li>
                                  <a
                                    href={ homeworkData.homework.url1 }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    { homeworkData.homework.url1 }
                                  </a>
                                </li> }
                                { homeworkData.homework.url2 && <li>
                                  <a
                                    href={ homeworkData.homework.url2 }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    { homeworkData.homework.url2 }
                                  </a></li> }
                                { homeworkData.homework.url3 && <li>
                                  <a
                                    href={ homeworkData.homework.url3 }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    { homeworkData.homework.url3 }
                                  </a>
                                </li> }
                                { homeworkData.homework.url4 && <li>
                                  <a
                                    href={ homeworkData.homework.url4 }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    { homeworkData.homework.url4 }
                                  </a>
                                </li> }
                                { homeworkData.homework.url5 && <li>
                                  <a
                                    href={ homeworkData.homework.url5 }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    { homeworkData.homework.url5 }
                                  </a>
                                </li> }
                              </ul> }
                          </Col>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={ 6 }>
                      <FormGroup>
                        <Col md={ 12 }>
                          <Col md={ 4 }> <ControlLabel > ADJUNTOS </ControlLabel> </Col>
                          <Col md={ 8 }>
                            <ul style={ { paddingLeft: "1%" } }>
                              { homeworkData.homework.files ?
                                homeworkData.homework.files.split(',').map(
                                  (attached) => {
                                    return (
                                      <li>
                                        <a
                                          href={ generalRoute + attached }
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          { attached }
                                        </a>
                                      </li>
                                    )
                                  }
                                ) :
                                "No tiene adjuntos"
                              }
                            </ul>
                          </Col>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Col>
                  <Col md={ 12 }>
                    <FormGroup>
                      <ControlLabel > MENSAJE DE RESPUESTA</ControlLabel>
                      <Row>
                        <textarea
                          name="msg"
                          style={ { width: "94%", height: "30px", marginLeft: "3%" } }
                          maxlength="70"
                          onChange={ e => this.onChange(e) }
                        />
                        <span style={ { marginLeft: "5%" } }><b>Nota:</b> El mensaje no puede sobrepasar los 70 caractéres.</span>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel > IMÁGENES ADJUNTOS</ControlLabel>
                        <AddButton name="Añadir imagen" onClick={ () => this._addImage(this) } numAttached={ images } />
                      </Col>
                      <Col md={ 12 }>

                        { this.state.imagesData.map(
                          (item, index) =>
                            <AttachedImage
                              data={ item }
                              key={ index }
                              name={ index + 1 }
                              canDelete={ this.state.imagesData.length - 1 === index }
                              onRemove={ () => this._removeImage(index) }
                              onChangeAttached={ e => this.onChange(e) }
                              showModal={ this.showModal }
                              closeModal={ this.closeModal }
                            />
                        )
                        }

                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col md={ 12 }>
                        <ControlLabel > ARCHIVOS ADJUNTOS</ControlLabel>
                        <AddButton name="Añadir adjunto" onClick={ () => this._add(this) } numAttached={ attached } />
                      </Col>
                      <Col md={ 12 }>

                        { this.state.attachedData.map(
                          (item, index) =>
                            <AttachedItem
                              data={ item }
                              key={ index }
                              name={ index + 1 }
                              canDelete={ this.state.attachedData.length - 1 === index }
                              onRemove={ () => this._remove(index) }
                              onChangeAttached={ e => this.onChange(e) }
                              showModal={ this.showModal }
                              closeModal={ this.closeModal }
                            />
                        )
                        }

                      </Col>
                    </FormGroup>
                  </Col>
                </Col>
                <Row>
                  <Col md={ 12 } style={ { marginLeft: "0px" } } >
                    <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                      <i className="fa fa-save"></i> Enviar
                    </Button>
                    <Button bsStyle="danger" pullRight fill type="button" style={ styles.saveButton } onClick={ returnToList }>
                      <i className="fa fa-chevron-left"></i> Cancelar
                    </Button>
                  </Col>
                </Row>
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default MyHomeworkEdit;
