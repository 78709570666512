import React, { Component } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import ContentMessage from "./components/ContentMessage.jsx";
import ButtonsConfirmCancel from "./components/ButtonsConfirmCancel.jsx";
import HeaderType from "./components/HeaderType.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import { ControlLabel } from "react-bootstrap";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import ControlGroup from "./components/ControlGroup";
import { Card } from "components/Card/Card.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import LoadingModal from "components/Modal/LoadingModal";

class NewStudentMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeId: '',
      studentId: '',
      subject: '',
      body: '',
      url1: '',
      url2: '',
      url3: '',
      url4: '',
      url5: '',
      attached1: '',
      attached2: '',
      attached3: '',
      attached4: '',
      attached5: '',
      programmed: false,
      fastResponse: false,
      repeat: false,
      period: '',
      dateSend: '',
      sendTime: '',
      sendTime1: '00',
      dateEnd: '',
      isLoading: false,
      isSubmitting: false,
      students: []
    };
  }
  async componentDidMount() {
    this.fetchStudentsId();
  }


  fetchStudentsId = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/student/list_select');
      const newData = response.data.students.map((item) => {
        return { value: item.id, label: `${item.name} ${item.last_name}` }
      })
      this.setState({ students: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleTypeId = (typeId) => {
    this.setState({ typeId: typeId });
  }

  handleContent = (p) => {
    this.setState({ [p.target.name]: p.target.value });
    //console.log([p.target.name] + " ... " + p.target.value)
  }

  onEditorChange = (evt) => {
    this.setState({
      body: evt.editor.getData()
    });
  }

  joinAttached = () => {
    const {
      attached1,
      attached2,
      attached3,
      attached4,
      attached5,
    } = this.state;
    let attached_str = "";
    let attacheds = [];
    attacheds.push(attached1)
    attacheds.push(attached2)
    attacheds.push(attached3)
    attacheds.push(attached4)
    attacheds.push(attached5)
    for (let index = 0; index < 5; index++) {
      if (attacheds[index] !== "") {
        if (index === 0) {
          attached_str = attached_str + attacheds[index]
        } else {
          attached_str = attached_str + "," + attacheds[index]
        }
      }
    }
    return attached_str
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      typeId,
      studentId,
      subject,
      body,
      url1,
      url2,
      url3,
      url4,
      url5,
      programmed,
      fastResponse,
      repeat,
      dateSend,
      sendTime,
      sendTime1,
      dateEnd,
      period
    } = this.state;
    if (programmed && dateSend === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo FECHA DE ENVÍO, ya que escogiste la opción MENSAJE PROGRAMADO."
      });
      return;
    }
    if (programmed && sendTime === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo HORA DE ENVÍO, ya que escogiste la opción MENSAJE PROGRAMADO."
      });
      return;
    }
    if (repeat && period === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo PERIODO, ya que escogiste la opción repetir mensaje."
      });
      return;
    }
    if (repeat && dateEnd === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo FECHA FIN, ya que escogiste la opción repetir mensaje."
      });
      return;
    }
    if (typeId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo TIPO DE MENSAJE"
      });
      return;
    }
    if (studentId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo ESTUDIANTE"
      });
      return;
    }

    this.setState({ isSubmitting: true });
    try {
      if (programmed) {
        if (repeat) {
          var porciones = sendTime.split(':');
          var hora = porciones[0] + ":" + sendTime1;
          await axios.post('message/add', {
            body,
            subject,
            type_id: typeId,
            type: 1,
            url: url1,
            url2,
            url3,
            url4,
            url5,
            student_id: studentId,
            programmed,
            repeat,
            fast_response: fastResponse,
            date_send: dateSend + " " + hora + ":00",
            date_end: dateEnd,
            period,
            attachment: this.joinAttached()
          });
        } else {
          var porciones = sendTime.split(':');
          var hora = porciones[0] + ":" + sendTime1;
          await axios.post('message/add', {
            body,
            subject,
            type_id: typeId,
            type: 1,
            url: url1,
            url2,
            url3,
            url4,
            url5,
            student_id: studentId,
            programmed,
            fast_response: fastResponse,
            repeat,
            date_send: dateSend + " " + hora + ":00",
            attachment: this.joinAttached()
          });
        }
      } else {
        await axios.post('message/add', {
          body,
          subject,
          type_id: typeId,
          type: 1,
          url: url1,
          url2,
          url3,
          url4,
          url5,
          student_id: studentId,
          attachment: this.joinAttached(),
          programmed,
          fast_response: fastResponse,
          repeat,
        });
      }
      this.props.history.replace("/admin/message/index")
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      students,
      isLoading,
      isSubmitting
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row style={ { paddingLeft: "30px" } }>
                <Col md={ 12 }>
                  <LoadingModal
                    show={ isSubmitting }
                    text="Enviando mensajes ..."
                  />
                  <Row>
                    <h4>Enviar mensaje a estudiante</h4>
                    { showNotification &&
                      <ErrorNotification
                        notificationMessage={ notificationMessage }
                        closeNotification={ () => this.setState({ showNotification: false }) }
                      />
                    }
                    <Col md={ 6 } >
                      <HeaderType onSelectTypeId={ this.handleTypeId } />
                      <Row>
                        <Col md={ 8 }>
                          <ControlLabel > ESTUDIANTE </ControlLabel>
                          { !isLoading ?
                            <Select options={ students }
                              name="studentId" className="basic-single"
                              classNamePrefix="select"
                              onChange={ (e) => this.setState({ 'studentId': e.value }) }
                              placeholder="Seleccione un estudiante" />
                            :
                            <Loader
                              type="Rings"
                              color="#00BFFF"
                              height={ 30 }
                              width={ 30 }
                              style={ { display: 'flex', justifyContent: 'center' } }
                            />
                          }

                        </Col>
                      </Row>
                      <ContentMessage onChange={ this.handleContent } onEditorChange={ this.onEditorChange } />
                    </Col>
                    <Col md={ 6 }>
                      <ControlGroup onChange={ this.handleContent } />
                    </Col>
                    <ButtonsConfirmCancel history={ this.props.history } />
                  </Row>
                </Col>
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default NewStudentMessage;
