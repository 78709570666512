

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      returnToList,
      userData
    } = this.props;
    return (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row style={ { marginTop: "2%" } }>
              <Col md={ 6 }>
                <h4>Datos del Usuario</h4>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > NOMBRES </ControlLabel> </Col>
                    <Col md={ 9 }> { userData.name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > APELLIDOS </ControlLabel> </Col>
                    <Col md={ 9 }> { userData.last_name } </Col>
                  </Col>
                </FormGroup>
                <FormGroup >
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > CORREO ELECTRÓNICO</ControlLabel> </Col>
                    <Col md={ 9 }> { userData.email } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > ROL</ControlLabel> </Col>
                    <Col md={ 9 }> { userData.role } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > INSTITUCIÓN</ControlLabel> </Col>
                    <Col md={ 9 }> { userData.institution_id } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > CREADO </ControlLabel> </Col>
                    <Col md={ 9 }> { userData.created_at } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 3 }> <ControlLabel > MODIFICADO </ControlLabel> </Col>
                    <Col md={ 9 }> { userData.updated_at } </Col>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
export default UserDetail