module.exports = {
    newRoleButton: {
        marginTop: "11%",
        width: "50%",
        marginLeft: "3%"
    },
    topButton: {
        margin: "1%",
        marginTop: "3%",
        width: "20%"
    }
}