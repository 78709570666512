import React from 'react'
import {
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, } from "react-bootstrap";
import { thParentMessageDetailArray } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class ParentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tutor: {},
      students: [],
      messages: [],
      studentsName: {}
    };
  }

  async componentDidMount() {
    this.fetchParentDetail();
  }

  fetchParentDetail = async () => {
    this.setState({ isLoading: true });
    const { parentData: { id } } = this.props;
    try {
      const response = await axios.post('/tutor/details', {
        tutor_id: id
      });
      var dict = {};
      response.data.tutor.student.map((item) => {
        return (
          dict[item.id] = `${item.name} ${item.last_name}`
        );
      })
      this.setState({
        tutor: response.data.tutor,
        students: response.data.tutor.student,
        messages: response.data.messages,
        studentsName: dict
      });

    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      returnToList
    } = this.props;
    const {
      isLoading,
      tutor,
      students,
      messages,
      studentsName
    } = this.state;
    return !isLoading ? (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row style={ { marginTop: "2%" } }>
              <Col md={ 5 }>
                <h4>Datos del Padre</h4>
                <FormGroup>
                  <ControlLabel> NOMBRES </ControlLabel>
                  <span style={ styles.data }>{ tutor.name }</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> APELLIDOS </ControlLabel>
                  <span style={ styles.data }>{ tutor.last_name }</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> CORREO ELECTRÓNICO </ControlLabel>
                  <span style={ styles.data }>{ tutor.email }</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> CREADO  </ControlLabel>
                  <span style={ styles.data }>{ tutor.created_at }</span>
                </FormGroup>
              </Col>
              <Col md={ 7 }>
                <h4>Estudiantes relacionados</h4><br />
                { students.length > 0 ?
                  <Table hover >
                    <thead>
                      <tr>
                        <th> NOMBRES</th>
                        <th> APELLIDOS</th>
                        <th> MATRICULA</th>
                        <th> NIVEL </th>
                        <th> GRADO </th>
                        <th> GRUPO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        students.map((student, keyRow) => {
                          return (
                            <tr key={ keyRow }>
                              <td>{ student.name }</td>
                              <td>{ student.last_name }</td>
                              <td>{ student.code }</td>
                              <td>{ student.level && student.level.name }</td>
                              <td>{ student.grade && student.grade.name }</td>
                              <td>{ student.group && student.group.name }</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </Table> :
                  "No tiene estudiantes relacionados aún"
                }
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { padding: "2%" } }>
                <h4>Mensajes enviados</h4><br />
                { messages.length > 0 ?
                  <Table hover >
                    <thead>
                      <tr>
                        { thParentMessageDetailArray.map((prop, key) => {
                          return <th key={ key }>{ prop }</th>;
                        }) }
                      </tr>
                    </thead>
                    <tbody>
                      { messages.map((message, keyRow) => {
                        return (
                          message.message &&
                          <tr key={ keyRow }>
                            <td>{ message.message.subject }</td>
                            <td>{ studentsName[message.student_id] }</td>
                            <td>
                              {
                                message.message.body.length > 30 ?
                                  <span dangerouslySetInnerHTML={ { __html: message.message.body.substring(0, 30) + " ..." } } />
                                  :
                                  <span dangerouslySetInnerHTML={ { __html: message.message.body } } />
                              }
                            </td>
                            <td>{ message.message.type_details && message.message.type_details.name }</td>
                            <td>{ message.state === false ? <span className="label label-danger">No</span> : <span className="label label-success">Si</span> }</td>
                            <td>{ message.created_at }</td>
                            <td>{ message.viewed === null ? "-" : message.viewed }</td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table> :
                  "No tiene mensajes enviados aún"
                }
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ () => returnToList(this.props.currentPage) } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default ParentDetail