module.exports = {
    saveButton: {
        marginTop: "2%",
    },
    dataN1: {
        paddingLeft: "4%",
        marginBottom: "1%"
    },
    dataN2: {
        paddingLeft: "2%"
    },
    passButton: {
        marginLeft: "-25px",
    },
    navButtom: {
        marginTop: "-10px",
        marginRight: "-20px",
        marginLeft: "-20px",
        marginBottom: "-8px",
    }
}