import React from "react";

import { Grid, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";

import styles from './styles';

function TabRouter({ title, defaultActiveKey, activeKey, tabs, history }) {
  return (
    <div className="content" style={ { paddingBottom: '0px' } }>
      <Grid fluid>
        <Row>
          <Col md={ 12 }>
            <Card
              title={ title }
              hideContent
              cardStyle={ {
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '0px',
                borderBottomLeftRadius: '0px',
                marginBottom: '0px',
                boxShadow: 'none',
              } }
              contentWrapperStyles={ { paddingBottom: '0px' } }
              content={
                <>
                  <Tabs defaultActiveKey={ defaultActiveKey } activeKey={ activeKey }>
                    { tabs.map(tab => (
                      <Tab disabled={ tab.disabled } eventKey={ tab.route } title={
                        tab.customTabComponent || (
                          <Button
                            bsStyle="default"
                            bsSize="lg"
                            simple
                            style={ styles.navButtom }
                            onClick={ () => history.replace(tab.route) }
                          > { tab.label } </Button>
                        )
                      }>
                      </Tab>
                    )) }
                  </Tabs>
                </>
              }>
            </Card>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default TabRouter;
