const styles = {
    passButton: {
        marginLeft: "-25px",
        paddingLeft: "8px",
        paddingRight: "8px"
    },
    labelModal: {
        fontWeight: "normal",
        marginTop: "2%"
    },
    saveButton: {
        marginRight: "1%"
    },
    data: {
        textAlign: "center",
        fontWeight: "bold"
    }
}

export default styles;
