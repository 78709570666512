import React from 'react'

import { Grid } from "react-bootstrap";
import ParentList from './parents/ParentList';
import ParentForm from './parents/ParentForm';
import ParentDetail from './parents/ParentDetail';
import ParentEdit from './parents/ParentEdit';
import StudentForm from './students/StudentForm';
import { Card } from "components/Card/Card.jsx";

class ParentMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      parentData: {},
      currentPage: 1
    };
  }

  handleBackToList = (currentPage) => {
    this.setState({ key: 0, currentPage: currentPage });
  };
  handleNewParent = () => {
    this.setState({ key: 1 })
  };
  handleDetailParent = (parentData, currentPage) => {
    this.setState({ key: 2, parentData: parentData, currentPage: currentPage });
  };

  handleEditParent = (parentData, currentPage) => {
    this.setState({ key: 3, parentData: parentData, currentPage: currentPage });
  };

  handleNewChild = (parentData, currentPage) => {
    this.setState({ key: 4, parentData: parentData, currentPage: currentPage });
  };

  render() {
    const {
      key,
      parentData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: <ParentList
                    detailParent={ this.handleDetailParent }
                    editParent={ this.handleEditParent }
                    newChild={ this.handleNewChild }
                    newParent={ this.handleNewParent }
                    currentPage={ currentPage }
                  />,
                  1: <ParentForm returnToList={ this.handleBackToList } />,
                  2: <ParentDetail returnToList={ this.handleBackToList } parentData={ parentData } currentPage={ currentPage } />,
                  3: <ParentEdit returnToList={ this.handleBackToList } parentData={ parentData } currentPage={ currentPage } />,
                  4: <StudentForm returnToList={ this.handleBackToList } fromParents="true" parentData={ parentData } currentPage={ currentPage } />,
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default ParentMenu