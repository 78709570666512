import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid } from "react-bootstrap";
import MyMessagesList from "views/tutorViews/MyMessagesList"
import MyMessagesDetail from "views/tutorViews/MyMessagesDetail"


class MyMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      messageData: {},
      currentPage: 1,
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };

  handleDetailMessages = (messageData, currentPage) => {
    this.setState({ key: 1, messageData: messageData, currentPage: currentPage });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }
  render() {
    const {
      key,
      messageData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0:
                    <MyMessagesList
                      detail={ this.handleDetailMessages }
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                      history={ this.props.history }
                    />
                  ,
                  1: <MyMessagesDetail
                    returnToList={ this.handleBackToList }
                    messageData={ messageData } />
                  ,
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default MyMessages;
