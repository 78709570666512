

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class ScannerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanner: {}
    };
  }

  async componentDidMount() {
    this.fetchScannerDetail();
  }

  fetchScannerDetail = async () => {
    this.setState({ isLoading: true });
    const { scannerData: { id } } = this.props;
    try {
      const response = await axios.post('/recorder/details', {
        recorder_id: id
      });
      this.setState({ scanner: response.data.recorder });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  render() {
    const {
      returnToList
    } = this.props;
    const {
      scanner,
      isLoading
    } = this.state;

    return !isLoading ? (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row>
              <Col md={ 6 }>
                <h4>Datos del Scanner</h4>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Nombres</ControlLabel> </Col>
                    <Col md={ 8 }> { scanner.firstname } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Apellidos </ControlLabel> </Col>
                    <Col md={ 8 }> { scanner.lastname } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Correo electrónico</ControlLabel> </Col>
                    <Col md={ 8 }> { scanner.email } </Col>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Usuario</ControlLabel> </Col>
                    <Col md={ 8 }> { scanner.username } </Col>
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Col md={ 12 } style={ styles.data }>
                    <Col md={ 4 }> <ControlLabel > Institución </ControlLabel> </Col>
                    <Col md={ 8 }> { scanner.institution && scanner.institution.name } </Col>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default ScannerDetail