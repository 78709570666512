import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import AttributeList from 'views/monitor/AttributeList.jsx';
import AttributeEdit from 'views/monitor/AttributeEdit';
import AttributeForm from 'views/monitor/AttributeForm';

class Attribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      attributeData: {},
      currentPage: 1,
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleEditAttribute = (attributeData) => {
    this.setState({ key: 1, attributeData: attributeData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      attributeData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <div style={ { marginLeft: '30px', marginRight: '30px' } }>
              <Card
                cardStyle={ {
                  boxShadow: 'none',
                } }
                content={
                  <Grid fluid>
                    {
                      {
                        0: (
                          <AttributeList
                            currentPage={ currentPage }
                            editAttribute={ this.handleEditAttribute }
                            newAttribute={ () => this.setState({ key: 2 }) }
                            goToNextPage={ this.goToNextPage }
                            goToPreviousPage={ this.goToPreviewsPage }
                          />
                        ),
                        1: <AttributeEdit returnToList={ this.handleBackToList } typeData={ attributeData } />,
                        2: <AttributeForm returnToList={ this.handleBackToList } />,
                      }[key]
                    }
                  </Grid>
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default Attribute;

