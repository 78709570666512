import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";

class MessageTableFact extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            "Tabla de m Facturación:  aun no definido"
          }
        />
      </div>
    );
  }
}

export default MessageTableFact;
