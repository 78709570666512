import React, { Component } from "react";
import {
  Row,
  Col,
  ControlLabel,
  FormGroup,
  Alert
} from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import { Card } from "components/Card/Card.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Select from 'react-select'
import styles from "./styles";
import Button from 'components/CustomButton/CustomButton';

class HomeworkReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      obs: '',
      isSubmitting: false,
      isLoading: false,
    };
    this.revisionOptions = [
      { value: 2, label: "Revisado" },
      { value: 3, label: "Revisado con observación" }
    ]
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      homeworkData,
      homeworkStudent
    } = this.props;
    const {
      status,
      obs
    } = this.state;
    if (status === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo ESTADO."
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      await axios.post('/homework_student/one_obs/' + homeworkStudent.id, {
        status,
        obs
      });
      this.props.detailHomework(homeworkData)
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      detailHomework,
      homeworkData,
      homeworkStudent
    } = this.props;
    const {
      showNotification,
      notificationMessage
    } = this.state
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row style={ { paddingLeft: "30px" } }>
                <h4>Revisar tarea</h4>
                <Col md={ 10 } >
                  {
                    homeworkStudent.status > 1 &&
                    <Alert bsStyle="info" >
                      <span>Esta tarea ya ha sido revisada, si envias otra revisión esta reemplazará  a la anterior.</span>
                    </Alert>
                  }
                  { showNotification &&
                    <ErrorNotification
                      notificationMessage={ notificationMessage }
                      closeNotification={ () => this.setState({ showNotification: false }) }
                    />
                  }
                </Col>
                <Col md={ 6 } >
                  <Col md={ 12 }>
                    <ControlLabel > MATERIA </ControlLabel>
                    <span style={ styles.data }>{ homeworkData.course.name }</span> <br />

                    <ControlLabel > ALUMNO </ControlLabel>
                    <span style={ styles.data }>
                      { homeworkStudent.student && `${homeworkStudent.student.name} ${homeworkStudent.student.last_name}` }</span>
                  </Col>
                  <Col md={ 6 }>
                    <FormGroup>
                      <ControlLabel> ESTADO</ControlLabel>
                      <Select options={ this.revisionOptions } name="gender" className="basic-single" isSearchable={ false }
                        classNamePrefix="select" onChange={ (e) => this.setState({ 'status': e.value }) }
                        placeholder="Seleccione una opción" />
                    </FormGroup>
                  </Col>
                  <Col md={ 10 }>
                    <FormGroup>
                      <ControlLabel > OBSERVACIÓN </ControlLabel>
                      <Row>
                        <textarea
                          name="obs"
                          style={ { width: "94%", height: "30px", marginLeft: "3%" } }
                          maxlength="70"
                          onChange={ e => this.onChange(e) }
                        />
                        <span style={ styles.data }><b>Nota:</b> la observación no puede sobrepasar los 70 caractéres.</span>
                      </Row>
                    </FormGroup>
                  </Col>
                </Col>
                <Row>
                  <Col md={ 12 } style={ { marginLeft: "0px" } } >
                    <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                      <i className="fa fa-save"></i> Enviar Revisión
                    </Button>
                    <Button bsStyle="danger" pullRight fill type="button" style={ styles.saveButton } onClick={ () => detailHomework(this.props.homeworkData) }>
                      <i className="fa fa-chevron-left"></i> Cancelar
                    </Button>
                  </Col>
                </Row>
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default HomeworkReview;
