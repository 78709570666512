import React, { Component } from "react";
import { Row, Col, Table, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import { generalRoute } from "variables/DomainRoutes.jsx";
import Loader from 'react-loader-spinner'
import ShowAttachedModal from "components/Modal/ShowAttachedModal";
import styles from './styles'
import LoadingModal from "components/Modal/LoadingModal";
import { payRoute } from "variables/DomainRoutes";

class MyMessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      messages: [],
      showAttachedModal: false,
      attacheds: '',
      titleAttached: '',
      typeShowModal: '',
      totalOfRecords: 0,
      totalOfPages: 0,
      isLoadingModal: false
    };
  }
  async componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      let response = {};
      if (currentPage === undefined) {
        this.setState({ currentPage: this.props.currentPage })
        response = await axios.post('/message/tutor/messages', {
          current_page: this.props.currentPage,
        });
      } else {
        response = await axios.post('/message/tutor/messages', {
          current_page: this.state.currentPage,
        });
      }
      console.log(response)
      this.setState({
        messages: response.data.messages.data,
        totalOfPages: response.data.messages.last_page,
        totalOfRecords: response.data.messages.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleShowModal = (message, type, num) => {
    if (num > 0) {
      if (type === "urls") {
        this.setState({
          showAttachedModal: true,
          attacheds: message,
          titleAttached: 'Se adjuntaron las siguientes urls',
          typeShowModal: type
        });
      } else {
        this.setState({
          showAttachedModal: true,
          attacheds: message,
          titleAttached: 'Se adjuntaron los siguientes archivos',
          typeShowModal: type
        });
      }
    }
  }

  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchMessages() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchMessages() }
    );
  }
  answer = async (messageId, type) => {

    this.setState({ isLoadingModal: true });
    try {
      let response = {};
      if (type === 1) {
        response = await axios.post('/message/fast_response', {
          message_id: messageId,
          fast_response: "Si"
        });
      } else {
        response = await axios.post('/message/fast_response', {
          message_id: messageId,
          fast_response: "No"
        });
      }
      this.fetchMessages();
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoadingModal: false });
    }
  }

  renderNumber = (message) => {
    if (message.url5) {
      return 5;
    }
    if (message.url4) {
      return 4;
    }
    if (message.url3) {
      return 3;
    }
    if (message.url2) {
      return 2;
    }
    if (message.url1) {
      return 1;
    }
    return 0
  }

  render() {
    const {
      detail
    } = this.props;
    const {
      isLoading,
      showAttachedModal,
      attacheds,
      titleAttached,
      typeShowModal,
      messages,
      totalOfPages,
      totalOfRecords,
      currentPage,
      isLoadingModal
    } = this.state;
    return !isLoading ? (
      <>
        <LoadingModal
          show={ isLoadingModal }
          text="Enviando respuesta ..."
        />
        <Row style={ { padding: "2%" } }>
          <h4>Mis mensajes</h4>
          <Row style={ { marginTop: "1%" } }>
            <Col md={ 12 }>
              {
                showAttachedModal &&
                <ShowAttachedModal
                  title={ titleAttached }
                  attacheds={ attacheds }
                  onClose={ () => this.setState({ showAttachedModal: false }) }
                  show={ showAttachedModal }
                  route={ generalRoute }
                  type={ typeShowModal }
                />
              }
              <Table striped hover >
                <thead>
                  <tr>
                    <th></th>
                    <th>Tipo </th>
                    <th>estudiante</th>
                    <th>asunto</th>
                    <th>mensaje</th>
                    <th>Recibido</th>
                    <th>urls</th>
                    <th>adjuntos</th>
                    <th>leido</th>
                    <th>Respuesta</th>
                    <th>acciones</th>
                  </tr>
                </thead>
                <tbody>
                  { messages.map((message) => {

                    return (
                      message.message &&
                      <tr key={ message.id }>
                        <td>
                          <img alt={ message.message.type_details.icon } src={ generalRoute + message.message.type_details.icon } style={ styles.iconList } />
                        </td>
                        <td>
                          { message.message.type_details && message.message.type_details.name }
                        </td>
                        <td>{ message.student && `${message.student.name} ${message.student.last_name}` }</td>
                        <td>{ message.message.subject }</td>

                        <td>
                          {
                            message.message.body.length > 30 ?
                              <span dangerouslySetInnerHTML={ { __html: message.message.body.substring(0, 30) + " ..." } } />
                              :
                              <span dangerouslySetInnerHTML={ { __html: message.message.body } } />
                          }
                        </td>
                        <td>{ message.created_at }</td>
                        <td>
                          <span className="label label-info"
                            onClick={ () => this.handleShowModal(message.message, "urls", this.renderNumber(message.message)) }>
                            <i className="fa fa-link"></i>
                            {
                              this.renderNumber(message.message)
                            }
                          </span>
                        </td>
                        <td>
                          <span className="label label-primary"
                            onClick={ () => this.handleShowModal(message.message, "attachment", message.message.attachment ? message.message.attachment.split(',').length : 0) }>
                            <i className="fa fa-paperclip"></i>{
                              message.message.attachment ? message.message.attachment.split(',').length : 0
                            }
                          </span>
                        </td>
                        <td>{ message.viewed === null ? <span className="label label-danger">No</span> : <span className="label label-success">Si</span> }</td>
                        <td>
                          { message.message.fast_response === true ?
                            (message.fast_response ? message.fast_response.fast_response : "Pendiente")
                            : "Ninguna" }
                        </td>
                        <td>
                          <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={ () => detail(message, currentPage) }><i className="fa fa-search"></i></Button>
                          {
                            message.message.payment !== null && message.message.payment.status === false &&
                            <>
                              <a
                                href={ payRoute + message.message.payment.code }
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button bsStyle="warning" bsSize="sm" fill title="Realizar pago" ><i className="fa fa-credit-card"></i></Button>
                              </a>
                            </>
                          }

                          { message.message.fast_response === true && message.fast_response === null &&
                            <>
                              <Button bsStyle="success" bsSize="sm" fill title="Responder SI" onClick={ () => this.answer(message.id, 1) }><i className="fa fa-check"></i></Button>
                              <Button bsStyle="danger" bsSize="sm" fill title="Responder NO" onClick={ () => this.answer(message.id, 0) }><i className="fa fa-times"></i></Button>
                            </>
                          }

                        </td>
                      </tr>
                    );
                  }) }
                </tbody>
              </Table>
              { messages.length > 0 && (
                <>
                  <Pager>
                    <Pager.Item
                      previous
                      disabled={ currentPage === 1 }
                      onClick={ () => this.goToPreviousPage() }
                    >
                      <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                    <Pager.Item>

                      { currentPage }/{ totalOfPages }

                    </Pager.Item>
                    <Pager.Item
                      disabled={ currentPage === totalOfPages }
                      next
                      onClick={ () => this.goToNextPage() }
                    >
                      Siguiente <i className="fa fa-chevron-right"></i>
                    </Pager.Item>
                  </Pager>
                  <Pager>
                    <Pager.Item>
                      Total: { totalOfRecords } registros
                </Pager.Item>
                  </Pager>
                </>
              ) }
            </Col>
          </Row>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MyMessagesList;
