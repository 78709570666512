import React, { Component } from "react";
import {
  Grid
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import TeachingCourseList from 'views/courses/TeachingCourseList';
import TeachingCourseEdit from 'views/courses/TeachingCourseEdit';

class TeachingCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      teachingCourseData: {},
      currentPage: 1,
    };
  }

  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleEditTeachingCourse = (teachingCourseData) => {
    this.setState({ key: 1, teachingCourseData: teachingCourseData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }


  render() {
    const {
      key,
      teachingCourseData,
      currentPage
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <div >
              <Card
                cardStyle={ {
                  boxShadow: 'none',
                } }
                content={
                  <Grid fluid>
                    {
                      {
                        0: (
                          <TeachingCourseList
                            editTeachingCourse={ this.handleEditTeachingCourse }
                            currentPage={ currentPage }
                            goToNextPage={ this.goToNextPage }
                            goToPreviousPage={ this.goToPreviewsPage }
                            history={ this.props.history }
                          />
                        ),
                        1: <TeachingCourseEdit returnToList={ this.handleBackToList } teachingCourseData={ teachingCourseData } />,
                      }[key]
                    }
                  </Grid>
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default TeachingCourse;
