

import React from 'react'
import { Row, Col, FormGroup, ControlLabel, Table } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import Loader from 'react-loader-spinner'
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import AddExtraStudentModal from 'components/Modal/AddExtraStudentModal.jsx'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';

class ExtraEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
      showAddExtraStudentModal: false,
      showConfirmDelete: false,
      actionName: "",
      selectedExtraStudent: {},
      isLoading: false
    };
  }

  async componentDidMount() {
    this.fetchDetails()
  }

  fetchDetails = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('extracurricular/details', {
        extracurricular_id: this.props.extraData.id,
      });
      this.setState({ students: response.data.students });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async handleCloseAddExtraStudentModal() {
    this.setState({ showAddExtraStudentModal: false });
    this.fetchDetails();
  };

  handleOpenConfirmDelete = (extra) => {
    this.setState({
      showConfirmDelete: true,
      actionName: extra.name,
      selectedExtraStudent: extra,
    });
  };

  handleDelete = async () => {
    const { selectedExtraStudent } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post('extracurricular/student/delete', {
        extracurricular_id: this.props.extraData.id,
        student_id: selectedExtraStudent.id
      });
      this.setState({ showConfirmDelete: false });
      this.fetchDetails();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      students,
      isSubmitting,
      showNotification,
      notificationMessage,
      showAddExtraStudentModal,
      showConfirmDelete,
      actionName,
      isLoading
    } = this.state;

    const {
      extraData,
      returnToList
    } = this.props;
    return !isSubmitting ? (

      <Row>
        <Col md={ 12 } style={ { padding: "2%", paddingTop: "0px" } }>
          <h4>Editar un extracurricular</h4>
          { showNotification &&
            <ErrorNotification
              notificationMessage={ notificationMessage }
              closeNotification={ () => this.setState({ showNotification: false }) }
            />
          }
          { showConfirmDelete &&
            <ConfirmDeleteModal
              onClose={ () => this.setState({ showConfirmDelete: false }) }
              show={ showConfirmDelete }
              thingToRemoveType="estudiante"
              thingToRemoveName={ actionName }
              onConfirm={ this.handleDelete }
            />
          }

          {
            showAddExtraStudentModal &&
            <AddExtraStudentModal
              extraId={ this.props.extraData.id }
              onClose={ () => this.handleCloseAddExtraStudentModal() }
              show={ showAddExtraStudentModal }
            />
          }

          <Row >

            <Col md={ 6 }>
              <Col md={ 8 }>
                <FormGroup>
                  <ControlLabel> NOMBRES </ControlLabel>
                  <br />
                  <span>{ extraData.name }</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> N° ESTUDIANTES  </ControlLabel>
                  <br />
                  <span>{ extraData.extracurricular_student_count }</span>
                </FormGroup>
              </Col>
            </Col>
            <Col md={ 6 }>
              <h5>Estudiantes
                   <Button
                  bsStyle="primary"
                  fill pullRight
                  onClick={ () => this.setState({ showAddExtraStudentModal: true }) }
                >
                  Agregar estudiante
                    </Button>
              </h5>
              <br />
              { !isLoading ?
                <Table striped hover >
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Apellidos</th>
                      <th>Matricula</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    { students.map((student) => {
                      return (
                        student &&
                        <tr key={ student.id }>
                          <td>{ student.name }</td>
                          <td>{ student.last_name }</td>
                          <td>{ student.code }</td>
                          <td>
                            <Button
                              bsStyle="danger" bsSize="sm"
                              fill title="Eliminar"
                              onClick={ () => this.handleOpenConfirmDelete(student) } >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    }) }
                  </tbody>
                </Table> :
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={ 100 }
                  width={ 100 }
                  style={ { display: 'flex', justifyContent: 'center' } }
                />
              }
            </Col>
          </Row>
          <Row>
            <Col md={ 12 } style={ { marginLeft: "0px" } }>
              <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                <i className="fa fa-chevron-left"></i> Regresar
                </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default ExtraEdit