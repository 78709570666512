import React from 'react'
import { Alert } from "react-bootstrap";
const ErrorNotification = ({ notificationMessage, closeNotification }) => {
  return (
    <Alert bsStyle="danger" style={ { margin: "1%" } }>
      <button
        type="button"
        aria-hidden="true"
        className="close"
        style={ { marginRight: "3%" } }
        onClick={ closeNotification }>
        &#x2715;
        </button>
      <span>
        { Array.isArray(notificationMessage) ? (
          <ul>
            { notificationMessage.map((msg) => {
              return (<li>{ msg }</li>)
            }) }
          </ul>
        ) : (
            notificationMessage
          ) }
      </span>
    </Alert>
  );
};
export default ErrorNotification;