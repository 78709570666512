module.exports = {
    saveButton: {
        marginTop: "5%",
        marginRight: "0px",
        marginLeft: "1%"
    },
    data: {
        marginLeft: "2%"
    },
    iconStyle: {
        width: "60px",
        height: "60px",
        border: "black",
        borderStyle: "dotted",
        borderRadius: "7px",
        margin: "5px"
    },
    iconSelect: {
        width: "65px",
        height: "65px",
        border: "blue",
        borderStyle: "solid",
        borderRadius: "7px",
        margin: "5px",
    },
    iconList: {
        width: "50px",
        height: "50px",
    },
    topButton: {
        margin: "1%",
        marginTop: "3%"
    }
}