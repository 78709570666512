import React from "react";
import { Row, Col, FormGroup, ControlLabel, FormControl, Alert } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles';
import axios from 'components/axios';
import Loader from 'react-loader-spinner';

class ConfPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      seePassword: false,
      passSubmited: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'danger',
      isSubmitting: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      actualPassword,
      newPassword,
      newPasswordConfirm,
    } = this.state;
    if (newPassword !== newPasswordConfirm) {
      this.setState({
        showNotification: true,
        notificationMessage: "Las contraseñas no coinciden"
      });
      return;
    }
    if (newPassword.length < 6) {
      this.setState({
        showNotification: true,
        notificationMessage: "La contraseña debe contener almenos 6 caracteres"
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      const { data } = await axios.post('/user/change-own-password', {
        current_password: actualPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirm,
      });
      if (data.msg) {
        this.setState({
          showNotification: true,
          notificationMessage: data.msg
        });
      } else {
        this.setState({
          showNotification: true,
          notificationMessage: 'Cuando vuelvas a inciar sesión, ingresaras con tu nueva contraseña',
          notificationType: 'success',
        });
      }
    } catch (err) {
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false, actualPassword: '', newPassword: '', newPasswordConfirm: '' });
    }
  }

  render() {
    const {
      actualPassword,
      newPassword,
      newPasswordConfirm,
      seePassword,
      showNotification,
      notificationMessage,
      notificationType,
      isSubmitting,
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={ !isSubmitting ? (
            <form onSubmit={ this.handleSubmit }>
              <Row>
                <Col md={ 12 } style={ { paddingRight: 15 } }>
                  { showNotification && (
                    <Alert bsStyle={ notificationType }>
                      <button type="button" aria-hidden="true" className="close" onClick={ () => this.setState({ showNotification: false }) }>
                        &#x2715;
                      </button>
                      <span>{ notificationMessage }</span>
                    </Alert>
                  ) }
                </Col>
                <Col md={ 6 } style={ { marginLeft: "3%" } }>
                  <Col md={ 8 }>
                    <FormGroup>
                      <ControlLabel style={ { marginTop: "2%" } }> CONTRASEÑA ACTUAL </ControlLabel>

                      <Row md={ 12 }>
                        <Col md={ 12 }>
                          <FormControl
                            required
                            value={ actualPassword }
                            type="password"
                            name="actual_password"
                            onChange={ e => this.setState({ actualPassword: e.target.value }) }
                          />
                        </Col>
                      </Row>

                    </FormGroup>

                    <FormGroup>
                      <ControlLabel style={ { marginTop: "2%" } }> CONTRASEÑA </ControlLabel>

                      <Row md={ 12 }>
                        <Col md={ 10 }>
                          <FormControl
                            required
                            value={ newPassword }
                            type={ seePassword === false ? "password" : "text" }
                            name="password"
                            onChange={ e => this.setState({ newPassword: e.target.value }) }
                          />
                        </Col>
                        <Col md={ 1 }>
                          {
                            seePassword === false ?
                              (<Button bsStyle="default" onClick={ () => this.setState({ seePassword: true }) }><i className="fa fa-eye"></i></Button>) :
                              (<Button bsStyle="primary" onClick={ () => this.setState({ seePassword: false }) }><i className="fa fa-eye-slash"></i></Button>)
                          }
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel style={ { marginTop: "2%" } }> CONFIRMAR CONTRASEÑA </ControlLabel>

                      <Row md={ 12 }>
                        <Col md={ 12 }>
                          <FormControl
                            required
                            value={ newPasswordConfirm }
                            type="password"
                            name="confirm_password"
                            onChange={ e => this.setState({ newPasswordConfirm: e.target.value }) }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { paddingLeft: 21, marginLeft: "3%" } }>
                  <Button bsStyle="success" fill type="submit" style={ styles.saveButton } onClick={ this.handleShowAlert }>
                    <i className="fa fa-refresh"></i> Actualizar contraseña
                  </Button>
                </Col>
              </Row>
            </form>
          ) : (
              <Loader
                type="Rings"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                style={ { display: 'flex', justifyContent: 'center' } }
              />
            ) }
        />
      </div>
    );
  }
}

export default ConfPass;
