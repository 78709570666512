module.exports = {
    saveButton: {
        marginTop: "5%",
        marginRight: "0px",
        marginLeft: "1%"
    },
    data: {
        marginLeft: "2%"
    },
    topButton: {
        margin: "1%",
        marginTop: "3%"
    },
}