import React from 'react'
import { Modal } from "react-bootstrap";
const HelpModal = ({ onClose, show, colsReqArray, colsOpArray, doc, image }) => {

  return (
    <Modal show={ show } onHide={ onClose }>
      <Modal.Header closeButton>
        <Modal.Title>¿Cómo cargar el archivo?</Modal.Title>
      </Modal.Header>
      <Modal.Body style={ { wordWrap: "break-word" } }>
        El Archivo debe tener las siguientes cabeceras:
        <ul>
          {
            colsReqArray.map((number) =>
              <li>{ number }</li>
            ) }
        </ul>
        { colsOpArray !== null && (
          <>
            Adicionalmente también se puede considerar:
          < ul >
              {
                colsOpArray.map((number) =>
                  <li>{ number }</li>
                )
              }
            </ul>
          </>
        ) }
        Puede descargar el siguiente archivo base para cargar su información. <br />
        <a
          href={ doc.path }
          target="_blank"
          rel="noopener noreferrer">
          { doc.title }
        </a><br />
        A continuación una foto de como debería verse el archivo al final:<br />
        <img src={ image } alt="calificaciones_image" style={ { width: "90%", padding: "2%" } } />

      </Modal.Body>
    </Modal >
  );
};
export default HelpModal;