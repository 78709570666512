import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';

class AttendanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedAttendance: null,
      attendances: [],
      currentPage: 1,
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
    };
  }
  async componentDidMount() {
    this.fetchAttendances();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchExtras();
    }
  }

  fetchAttendances = async () => {
    const { attendanceData } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/assist/liststudent', {
        student_id: attendanceData.id
      });
      this.setState({
        attendances: response.data.assists.data,
        totalOfPages: response.data.assists.last_page,
        totalOfRecords: response.data.assists.total
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      returnToList
    } = this.props;
    const {
      goToNextPage,
      currentPage,
      goToPreviousPage,
      isLoading,
      totalOfPages,
      totalOfRecords,
      attendances
    } = this.state;
    return !isLoading ? (
      <>
        <Row>
          <Col md={ 12 } style={ { marginLeft: "2%" } }>
            <Button bsStyle="primary" fill type="button" onClick={ returnToList } >
              <i className="fa fa-chevron-left"></i> Regresar a la lista de estudiantes
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={ 12 }>
            <Table striped hover >
              <thead>
                <tr>
                  <th>Fecha y hora </th>
                  <th>Tipo </th>
                  <th>Registrado por</th>
                </tr>
              </thead>
              <tbody>
                { attendances.map((att) => {
                  return (
                    <tr key={ att.id }>
                      <td>{ att.created_at }</td>
                      <td>{ att.kind === true ?
                        <span className="label label-success">Ingreso </span>
                        :
                        <span className="label label-warning">Salida </span>
                      }</td>
                      <td>{ att.name }</td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
            { attendances.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                  <Pager.Item>

                    { currentPage }/{ totalOfPages }

                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
                </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default AttendanceDetail;
