import React from 'react'
import { Modal, FormControl, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'

class CycleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      id: '',
      isSubmitting: false
    };
  }

  componentDidMount() {
    const cycleData = this.props.cycleData;
    if (cycleData !== null) {
      this.setState({ name: cycleData.name, id: cycleData.id })
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, id } = this.state;
    this.setState({ isSubmitting: true });
    try {
      var url;
      if (this.props.cycleData !== null) {
        url = `cycle/edit/${id}`;
      } else {
        url = 'cycle/add';
      }
      await axios.post(url, {
        name,
      });
      this.props.reload();
      this.props.onClose();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      onClose,
      show,
      cycleData
    } = this.props;
    const {
      name,
      isSubmitting,
      showNotification,
      notificationMessage,
    } = this.state;
    return (
      <Modal show={ show } onHide={ onClose } backdrop="static">
        <form onSubmit={ this.handleSubmit }>
          <Modal.Header closeButton >
            <Modal.Title>
              { cycleData === null ?
                "Agregar un ciclo" : "Editar ciclo" }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={ { wordWrap: "break-word" } }>
            <Row>
              <Col md={ 10 }>
                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
                { !isSubmitting ? (
                  <FormGroup>
                    <Col md={ 12 }>
                      <ControlLabel style={ styles.labelModal }> NOMBRE</ControlLabel>
                      <FormControl
                        required
                        name="name"
                        value={ name }
                        onChange={ (e) => this.setState({ name: e.target.value }) }
                      />
                    </Col>
                  </FormGroup>
                ) : (
                    <Loader
                      type="Rings"
                      color="#00BFFF"
                      height={ 100 }
                      width={ 100 }
                      style={ { display: 'flex', justifyContent: 'center' } }
                    />
                  ) }


              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer >
            <Button bsStyle="success" fill type="submit" style={ styles.saveButton }>
              <i className="fa fa-save"></i> Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal >
    );
  }
}
export default CycleModal