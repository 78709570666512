import React, { Component } from "react";
import Button from 'components/CustomButton/CustomButton';
class AddButton extends Component {
    _add() {
        if (this.props.onClick)
            this.props.onClick();
    }

    render() {
        return (
            <Button bsStyle="primary" bsSize="xs" fill pullRight onClick={ this._add.bind(this) } disabled={ this.props.numAttached > 4 }>
                <i className="fa fa-plus"></i>{ this.props.name }
            </Button>
        )
    }
}
export default AddButton;