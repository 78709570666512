import React from 'react'
import { Modal, Row, Col, ControlLabel, FormControl, Alert } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles.js'
import Loader from 'react-loader-spinner'
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';

class ConfirmDeleteDB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeDeleteDB: '',
      secondCode: '',
      showInput: false,
      requestingCode: false,
      isDeleting: false,
      onFinish: false
    };
  }

  lookForDeleteCode = async () => {
    this.setState({ requestingCode: true });
    try {
      const response = await axios.post('/student/director/alert ');
      this.setState({ codeDeleteDB: response.data.random_code, showInput: true });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ requestingCode: false });
    }
  }

  deleteAllDB = async () => {
    const {
      codeDeleteDB, secondCode
    } = this.state
    if (codeDeleteDB !== parseInt(secondCode, 10)) {
      this.setState({
        showNotification: true,
        notificationType: "danger",
        notificationMessage: "El código de confirmación introducido no es correcto."
      });
      return;
    }
    this.setState({ isDeleting: true });
    try {
      await axios.post('/student/database/delete ', {
        institution_id: this.props.institution_id
      });
      this.setState({
        showNotification: true,
        notificationType: "success",
        notificationMessage: "La base de datos fue eliminada con éxito. Actualice la página para ver los cambios."
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({
        onFinish: true,
        isDeleting: false
      });
    }
  }

  render() {
    const {
      show,
      onClose
    } = this.props

    const {
      isDeleting,
      requestingCode,
      showInput,
      showNotification,
      notificationType,
      notificationMessage,
      onFinish
    } = this.state

    return (
      <Modal show={ show } backdrop="static" onHide={ onClose } >
        <Modal.Header closeButton >
          <Modal.Title>Confirmar eliminación de Base de Datos</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Row>
            {
              showInput ?
                (
                  <>
                    { !onFinish && (
                      <Col md={ 12 }>
                        <Alert bsStyle="info" style={ { margin: "1%" } }>
                          Se le envió un correo al director de la escuela con un código de confirmación, insértelo a continuación para continuar con la operación:<br />
                        </Alert>
                        <ControlLabel> <b> CÓDIGO </b> </ControlLabel>
                        <FormControl required type="number" name="codeDeleteDB"
                          onChange={ (e) => this.setState({ secondCode: e.target.value }) }
                        />
                      </Col>) }
                  </>
                )
                : (
                  <Col md={ 12 }>
                    ¿Está seguro que desea eliminar TODA la Base de Datos?<br />
                  </Col>
                )
            }

            <Col md={ 12 }>
              { showNotification &&
                < ModalNotification
                  type={ notificationType }
                  notificationMessage={ notificationMessage }
                  closeNotification={ () => this.setState({ showNotification: false }) }
                />
              }
            </Col>
          </Row>
          {
            requestingCode &&
            <>
              <Alert bsStyle="warning" style={ { margin: "1%" } }>
                Enviando correo con código de confirmación.
            </Alert>
              <Loader
                type="Rings"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                style={ { display: 'flex', justifyContent: 'center' } }
              />
            </>

          }
          {
            isDeleting &&
            <>
              <Alert bsStyle="warning" style={ { margin: "1%" } }>
                Eliminando Base de Datos
            </Alert>
              <Loader
                type="Rings"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                style={ { display: 'flex', justifyContent: 'center' } }
              />
            </>

          }

        </Modal.Body>
        <Modal.Footer >
          {
            !onFinish &&
            (
              <>
                <Button bsStyle="default" fill type="button" style={ styles.saveButton } onClick={ this.props.onClose }>
                  Cancelar
              </Button>
                {
                  showInput ? (
                    <Button bsStyle="danger" fill type="button" style={ styles.saveButton } onClick={ () => this.deleteAllDB() }>
                      Continuar con la eliminación
                    </Button>
                  ) : (
                      <Button bsStyle="danger" fill type="button" style={ styles.saveButton } onClick={ () => this.lookForDeleteCode() }>
                        Confirmar
                      </Button>
                    )
                }
              </>
            )
          }


        </Modal.Footer>
      </Modal >
    );
  }
} export default ConfirmDeleteDB;