import React, { Component } from "react";
import {
  Row,
  Col,
  Alert
} from "react-bootstrap";
import ContentMessage from "./components/ContentMessage.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import ButtonsConfirmCancel from "./components/ButtonsConfirmCancel.jsx";
import HeaderType from "./components/HeaderType.jsx";
import ControlGroup from "./components/ControlGroup";
import { Card } from "components/Card/Card.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import LoadingModal from "components/Modal/LoadingModal";

class NewMassive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeId: '',
      subject: '',
      body: '',
      url1: '',
      url2: '',
      url3: '',
      url4: '',
      url5: '',
      attached1: '',
      attached2: '',
      attached3: '',
      attached4: '',
      attached5: '',
      programmed: false,
      fastResponse: false,
      repeat: false,
      period: '',
      dateSend: '',
      sendTime1: '00',
      sendTime: '',
      dateEnd: '',
      isSubmitting: false,
    };
  }

  handleTypeId = (typeId) => {
    this.setState({ typeId: typeId });
  }

  handleContent = (p) => {
    this.setState({ [p.target.name]: p.target.value });
  }

  onEditorChange = (evt) => {
    this.setState({
      body: evt.editor.getData()
    });
  }

  joinAttached = () => {
    const {
      attached1,
      attached2,
      attached3,
      attached4,
      attached5,
    } = this.state;
    let attached_str = "";
    let attacheds = [];
    attacheds.push(attached1)
    attacheds.push(attached2)
    attacheds.push(attached3)
    attacheds.push(attached4)
    attacheds.push(attached5)
    for (let index = 0; index < 5; index++) {
      if (attacheds[index] !== "") {
        if (index === 0) {
          attached_str = attached_str + attacheds[index]
        } else {
          attached_str = attached_str + "," + attacheds[index]
        }
      }
    }
    return attached_str
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      typeId,
      subject,
      body,
      url1,
      url2,
      url3,
      url4,
      url5,
      programmed,
      fastResponse,
      repeat,
      dateSend,
      sendTime,
      sendTime1,
      dateEnd,
      period
    } = this.state;
    if (typeId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo TIPO DE MENSAJE"
      });
      return;
    }
    if (programmed && dateSend === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo FECHA DE ENVÍO, ya que escogiste la opción MENSAJE PROGRAMADO."
      });
      return;
    }
    if (programmed && sendTime === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo HORA DE ENVÍO, ya que escogiste la opción MENSAJE PROGRAMADO."
      });
      return;
    }
    if (repeat && period === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo PERIODO, ya que escogiste la opción repetir mensaje."
      });
      return;
    }
    if (repeat && dateEnd === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo FECHA FIN, ya que escogiste la opción repetir mensaje."
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      if (programmed) {
        if (repeat) {
          var porciones = sendTime.split(':');
          var hour = porciones[0] + ":" + sendTime1;
          await axios.post('message/add', {
            body,
            subject,
            type_id: typeId,
            type: 2,
            url: url1,
            url2,
            url3,
            url4,
            url5,
            programmed,
            fast_response: fastResponse,
            repeat,
            date_send: dateSend + " " + hour + ":00",
            date_end: dateEnd,
            period,
            attachment: this.joinAttached()
          });
        } else {
          porciones = sendTime.split(':');
          console.log("porciones", porciones)
          hour = porciones[0] + ":" + sendTime1;
          await axios.post('message/add', {
            body,
            subject,
            type_id: typeId,
            type: 2,
            url: url1,
            url2,
            url3,
            url4,
            url5,
            programmed,
            fast_response: fastResponse,
            repeat,
            date_send: dateSend + " " + hour + ":00",
            attachment: this.joinAttached()
          });
        }
      } else {
        await axios.post('message/add', {
          body,
          subject,
          type_id: typeId,
          type: 2,
          url: url1,
          url2,
          url3,
          url4,
          url5,
          programmed,
          fast_response: fastResponse,
          repeat,
          attachment: this.joinAttached()
        });
      }
      this.props.history.replace("/admin/message/index")
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      isSubmitting
    } = this.state
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row style={ { paddingLeft: "30px" } }>
                <h4>Enviar mensaje masivo</h4>
                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
                <LoadingModal
                  show={ isSubmitting }
                  text="Enviando mensajes ..."
                />
                <Col md={ 6 } >
                  <HeaderType onSelectTypeId={ this.handleTypeId } />
                  <Row>
                    <Col md={ 12 } >
                      <Alert bsStyle="success" >
                        <span>El mensaje será enviado a toda la institución.</span>
                      </Alert>
                    </Col>
                  </Row>
                  <ContentMessage onChange={ this.handleContent } onEditorChange={ this.onEditorChange } />
                </Col>
                <Col md={ 6 }>
                  <ControlGroup onChange={ this.handleContent } />
                </Col>
                <ButtonsConfirmCancel history={ this.props.history } />
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default NewMassive;
