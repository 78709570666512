import React from "react";
import { Row, Col, Table, Pager } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Loader from 'react-loader-spinner';
import LevelModal from 'components/Modal/LevelModal'
import GroupModal from 'components/Modal/GroupModal'
import GradeModal from 'components/Modal/GradeModal'
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';

class ConfLevels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showLevelModal: false,
      showGroupModal: false,
      showGradeModal: false,
      selectedData: null,
      showConfirmDeleteLevel: false,
      showConfirmDeleteGroup: false,
      showConfirmDeleteGrade: false,
      actionName: "",
      isDeleting: false,
      levels: [],
      grades: [],
      groups: [],
      notificationMessage: '',
      showNotification: false,
      currentPageLevels: 1,
      totalOfPagesLevels: 0,
      totalOfRecordsLevels: 0,
      currentPageGroups: 1,
      totalOfPagesGroups: 0,
      totalOfRecordsGroups: 0,
      currentPageGrades: 1,
      totalOfPagesGrades: 0,
      totalOfRecordsGrades: 0
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('level/list', { current_page: this.state.currentPageLevels });
      this.setState({
        levels: response.data.Levels.data,
        totalOfPagesLevels: response.data.Levels.last_page,
        totalOfRecordsLevels: response.data.Levels.total,
      });
      const response1 = await axios.post('grade/list', { current_page: this.state.currentPageGrades });
      this.setState({
        grades: response1.data.Grades.data,
        totalOfPagesGrades: response1.data.Grades.last_page,
        totalOfRecordsGrades: response1.data.Grades.total,
      });
      const response2 = await axios.post('group/list', { current_page: this.state.currentPageGroups });
      this.setState({
        groups: response2.data.Groups.data,
        totalOfPagesGroups: response2.data.Groups.last_page,
        totalOfRecordsGroups: response2.data.Groups.total,
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenConfirmDelete = (modal, data) => {
    this.setState({
      [modal]: true,
      actionName: data.name,
      selectedData: data,
    });
  };
  handleDelete = async (type) => {
    const { selectedData } = this.state;
    this.setState({ isDeleting: true });
    try {
      switch (type) {
        case "level":
          await axios.post(`level/delete/${selectedData.id}`);
          this.setState({ showConfirmDeleteLevel: false });
          break;
        case "group":
          await axios.post(`group/delete/${selectedData.id}`);
          this.setState({ showConfirmDeleteGroup: false });
          break;
        case "grade":
          await axios.post(`grade/delete/${selectedData.id}`);
          this.setState({ showConfirmDeleteGrade: false });
          break;
        default:
          console.log("switch:Unexpected frontend case")
      }
      this.fetchData()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      switch (type) {
        case "level":
          this.setState({ showConfirmDeleteLevel: false });
          break;
        case "group":
          this.setState({ showConfirmDeleteGroup: false });
          break;
        case "grade":
          this.setState({ showConfirmDeleteGrade: false });
          break;
        default:
          console.log("switch:Unexpected frontend case")
      }
      this.setState({ isDeleting: false })
    } finally {
      this.setState({ isDeleting: false });
    }
  }
  goToNextPage = async (type) => {
    switch (type) {
      case "grades":
        this.setState((currentState) => ({ currentPageGrades: currentState.currentPageGrades + 1 }),
          () => { this.fetchData() }
        );
        break;
      case "groups":
        this.setState((currentState) => ({ currentPageGroups: currentState.currentPageGroups + 1 }),
          () => { this.fetchData() }
        );
        break;
      case "levels":
        this.setState((currentState) => ({ currentPageLevels: currentState.currentPageLevels + 1 }),
          () => { this.fetchData() }
        );
        break;
      default:
      // code block
    }
  }

  goToPreviousPage = (type) => {
    switch (type) {
      case "grades":
        this.setState((currentState) => ({ currentPageGrades: currentState.currentPageGrades - 1 }),
          () => { this.fetchData() }
        );
        break;
      case "groups":
        this.setState((currentState) => ({ currentPageGroups: currentState.currentPageGroups - 1 }),
          () => { this.fetchData() }
        );
        break;
      case "levels":
        this.setState((currentState) => ({ currentPageLevels: currentState.currentPageLevels - 1 }),
          () => { this.fetchData() }
        );
        break;
      default:
      // code block
    }
  }

  render() {
    const {
      isLoading,
      levels,
      grades,
      groups,
      showLevelModal,
      showGroupModal,
      showGradeModal,
      showConfirmDeleteLevel,
      showConfirmDeleteGroup,
      showConfirmDeleteGrade,
      actionName,
      selectedData,
      showNotification,
      notificationMessage,
      currentPageGrades,
      totalOfPagesGrades,
      totalOfRecordsGrades,
      currentPageGroups,
      totalOfPagesGroups,
      totalOfRecordsGroups,
      currentPageLevels,
      totalOfPagesLevels,
      totalOfRecordsLevels
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={ !isLoading ? (
            <>
              <Row>
                <Col md={ 12 } style={ { padding: "2%", marginLeft: "3%" } }>
                  <Row >
                    { showNotification &&
                      <Col md={ 11 }>
                        <ErrorNotification
                          notificationMessage={ notificationMessage }
                          closeNotification={ () => this.setState({ showNotification: false }) }
                        />
                      </Col>
                    }
                    { showLevelModal &&
                      <LevelModal
                        onClose={ () => this.setState({ showLevelModal: false, selectedData: null }) }
                        show={ showLevelModal }
                        levelData={ selectedData }
                        reload={ () => { this.fetchData() } }
                      /> }
                    {
                      showConfirmDeleteLevel &&
                      <ConfirmDeleteModal
                        onClose={ () => this.setState({ showConfirmDeleteLevel: false }) }
                        show={ showConfirmDeleteLevel }
                        thingToRemoveType="nivel"
                        thingToRemoveName={ actionName }
                        onConfirm={ () => this.handleDelete("level") }
                      />
                    }

                    <Col md={ 5 }>
                      <h4>Niveles
                        <Button
                          bsStyle="primary" pullRight fill
                          onClick={ () => this.setState({ showLevelModal: true }) }
                        >
                          Agregar Nivel
                          </Button>
                      </h4>
                      <Table striped hover >
                        <thead>
                          <tr>
                            <td>Nombre</td>
                            <td>Acciones</td>
                          </tr>
                        </thead>
                        <tbody>
                          { levels.map((level) => {
                            return (
                              <tr key={ level.id }>
                                <td>{ level.name }</td>
                                <td>
                                  <Button bsStyle="success" bsSize="sm" fill title="Editar"
                                    onClick={ () => this.setState({ showLevelModal: true, selectedData: level }) }
                                  ><i className="fa fa-pencil"></i></Button>
                                  <Button bsStyle="danger" bsSize="sm" fill title="Eliminar"
                                    onClick={ () => this.handleOpenConfirmDelete("showConfirmDeleteLevel", level) }
                                  ><i className="fa fa-trash"></i></Button>
                                </td>
                              </tr>
                            );
                          }) }
                        </tbody>
                      </Table>
                      { levels.length > 0 && (
                        <>
                          <Pager>
                            <Pager.Item
                              previous
                              disabled={ currentPageLevels === 1 }
                              onClick={ () => this.goToPreviousPage("levels") }
                            >
                              <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                            <Pager.Item>

                              { currentPageLevels }/{ totalOfPagesLevels }

                            </Pager.Item>
                            <Pager.Item
                              disabled={ currentPageLevels === totalOfPagesLevels }
                              next
                              onClick={ () => this.goToNextPage("levels") }
                            >
                              Siguiente <i className="fa fa-chevron-right"></i>
                            </Pager.Item>
                          </Pager>
                          <Pager>
                            <Pager.Item>
                              Total: { totalOfRecordsLevels } registros
                        </Pager.Item>
                          </Pager>
                        </>
                      ) }
                      {
                        showConfirmDeleteGroup &&
                        <ConfirmDeleteModal
                          onClose={ () => this.setState({ showConfirmDeleteGroup: false }) }
                          show={ showConfirmDeleteGroup }
                          thingToRemoveType="grupo"
                          thingToRemoveName={ actionName }
                          onConfirm={ () => this.handleDelete("group") }
                        />
                      }
                      { showGroupModal &&
                        <GroupModal
                          onClose={ () => this.setState({ showGroupModal: false, selectedData: null }) }
                          show={ showGroupModal }
                          groupData={ selectedData }
                          reload={ () => this.fetchData() }
                        /> }
                      <h4>
                        Grupos
                        <Button bsStyle="primary" pullRight fill
                          onClick={ () => this.setState({ showGroupModal: true }) }
                        >
                          Agregar Grupo
                        </Button>
                      </h4>
                      <Table striped hover >
                        <thead>
                          <tr>
                            <td>Nombre</td>
                            <td>Acciones</td>
                          </tr>
                        </thead>
                        <tbody>
                          { groups.map((group) => {
                            return (
                              <tr key={ group.id }>
                                <td>{ group.name }</td>
                                <td>
                                  <Button
                                    bsStyle="success" bsSize="sm" fill title="Editar"
                                    onClick={ () => this.setState({ showGroupModal: true, selectedData: group }) }
                                  ><i className="fa fa-pencil"></i></Button>
                                  <Button
                                    bsStyle="danger" bsSize="sm" fill title="Eliminar"
                                    onClick={ () => this.handleOpenConfirmDelete("showConfirmDeleteGroup", group) }
                                  ><i className="fa fa-trash"></i></Button>
                                </td>
                              </tr>
                            );
                          }) }
                        </tbody>
                      </Table>
                      { groups.length > 0 && (
                        <>
                          <Pager>
                            <Pager.Item
                              previous
                              disabled={ currentPageGroups === 1 }
                              onClick={ () => this.goToPreviousPage("groups") }
                            >
                              <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                            <Pager.Item>

                              { currentPageGroups }/{ totalOfPagesGroups }

                            </Pager.Item>
                            <Pager.Item
                              disabled={ currentPageGroups === totalOfPagesGroups }
                              next
                              onClick={ () => this.goToNextPage("groups") }
                            >
                              Siguiente <i className="fa fa-chevron-right"></i>
                            </Pager.Item>
                          </Pager>
                          <Pager>
                            <Pager.Item>
                              Total: { totalOfRecordsGroups } registros
                        </Pager.Item>
                          </Pager>
                        </>
                      ) }
                    </Col>
                    <Col md={ 1 }></Col>
                    <Col md={ 5 }>
                      {
                        showConfirmDeleteGrade &&
                        <ConfirmDeleteModal
                          onClose={ () => this.setState({ showConfirmDeleteGrade: false }) }
                          show={ showConfirmDeleteGrade }
                          thingToRemoveType="grado"
                          thingToRemoveName={ actionName }
                          onConfirm={ () => this.handleDelete("grade") }
                        />
                      }
                      { showGradeModal &&
                        <GradeModal
                          onClose={ () => this.setState({ showGradeModal: false, selectedData: null }) }
                          show={ showGradeModal }
                          gradeData={ selectedData }
                          reload={ () => this.fetchData() }
                        /> }
                      <h4>
                        Grados
                        <Button bsStyle="primary" pullRight fill
                          onClick={ () => this.setState({ showGradeModal: true }) }
                        >
                          Agregar Grado
                          </Button>
                      </h4>
                      <Table striped hover >
                        <thead>
                          <tr>
                            <td>Orden</td>
                            <td>Nivel</td>
                            <td>Nombre</td>
                            <td>Acciones</td>
                          </tr>
                        </thead>
                        <tbody>
                          { grades.map((grade) => {
                            return (
                              <tr key={ grade.id }>
                                <td>{ grade.order }</td>
                                <td>{ grade.level.name }</td>
                                <td>{ grade.name }</td>
                                <td>
                                  <Button
                                    bsStyle="success" bsSize="sm" fill title="Editar"
                                    onClick={ () => this.setState({ showGradeModal: true, selectedData: grade }) }
                                  ><i className="fa fa-pencil"></i></Button>
                                  <Button
                                    bsStyle="danger" bsSize="sm" fill title="Eliminar"
                                    onClick={ () => this.handleOpenConfirmDelete("showConfirmDeleteGrade", grade) }
                                  ><i className="fa fa-trash"></i></Button>
                                </td>
                              </tr>
                            );
                          }) }
                        </tbody>
                      </Table>
                      { grades.length > 0 && (
                        <>
                          <Pager>
                            <Pager.Item
                              previous
                              disabled={ currentPageGrades === 1 }
                              onClick={ () => this.goToPreviousPage("grades") }
                            >
                              <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                            <Pager.Item>

                              { currentPageGrades }/{ totalOfPagesGrades }

                            </Pager.Item>
                            <Pager.Item
                              disabled={ currentPageGrades === totalOfPagesGrades }
                              next
                              onClick={ () => this.goToNextPage("grades") }
                            >
                              Siguiente <i className="fa fa-chevron-right"></i>
                            </Pager.Item>
                          </Pager>
                          <Pager>
                            <Pager.Item>
                              Total: { totalOfRecordsGrades } registros
                        </Pager.Item>
                          </Pager>
                        </>
                      ) }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
              <Loader
                type="Rings"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                style={ { display: 'flex', justifyContent: 'center' } }
              />
            )
          } />
      </div>
    )
  }
}

export default ConfLevels;
