module.exports = {
    newRoleButton: {
        marginTop: "30%"
    },
    passButton: {
        marginLeft: "-10px",
        paddingRight: "10px",
        paddingLeft: "10px"
    },
    topButton: {
        margin: "1%",
        marginTop: "3%"
    },
    saveButton: {
        marginTop: "5%",
        marginRight: "0px",
        marginLeft: "1%"
    },
    data: {
        marginTop: "1%"
    }
}