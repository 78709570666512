import React from 'react'

import { Grid, Row, Col } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import RoleList from '../roles/RoleList';
import ModalNewRole from 'views/roles/modalNewRole';
import styles from './styles';
import { Card } from "components/Card/Card.jsx";

class RoleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewRoleModal: false
    };
  }

  handleShowNewRoleModal = () => {
    this.setState({ showNewRoleModal: true });
  };

  handleCloseNewRoleModal = () => {
    this.setState({ showNewRoleModal: false });
  };

  render() {
    const {
      showNewRoleModal
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <Row>
                <Col md={ 6 } style={ { paddingLeft: "2%" } }>
                  <Button bsStyle="primary" fill style={ styles.topButton } onClick={ this.handleShowNewRoleModal } >
                    Nuevo Rol
                  </Button>
                </Col>
                <Col md={ 6 }>
                  <Button bsStyle="primary" pullRight style={ styles.topButton, { "display": "none" } } disabled >Exportar Excel</Button>
                </Col>
              </Row>
              <Row>
                <ModalNewRole
                  onClose={ this.handleCloseNewRoleModal }
                  open={ showNewRoleModal }
                />
                { !showNewRoleModal && <RoleList /> }
              </Row>
            </Grid>
          }
        />
      </div>
    );
  }
}
export default RoleMenu