/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { rootRoute } from "variables/DomainRoutes.jsx";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";

import logo from "assets/img/logo.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      logo: '',
      nameSchool: '',
      isTutor: false,
      privileges: '',
      assist: false,
      payments: false
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const institutionData = JSON.parse(localStorage.getItem('INSTITUTION'));
    if (institutionData.logo !== "") {
      this.setState({ logo: `${rootRoute}${institutionData.logo_folder}${institutionData.logo}` });
    }
    const localUser = JSON.parse(localStorage.getItem('USER'))
    if (localUser.role_id === 4) {
      this.setState({
        nameSchool: institutionData.name,
        isTutor: localUser.role_id === 4,
        privileges: "",
        assist: institutionData.assist,
        payments: institutionData.payment
      });
    } else {
      this.setState({
        nameSchool: institutionData.name,
        isTutor: localUser.role_id === 4,
        privileges: localUser.rol.privileges,
        assist: institutionData.assist,
        payments: institutionData.payment
      });
    }

  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };

    const { isTutor, assist, payments } = this.state;
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={ this.props.color }
        data-image={ this.props.image }
      >
        { this.props.hasImage ? (
          <div className="sidebar-background" style={ sidebarBackground } />
        ) : (
            null
          ) }
        <div className="logo">
          <div>
            <img src={
              (this.state.logo !== "") ? this.state.logo : logo
            } alt="logo_image" className="img-responsive img-circle"
              style={ { height: "150px", marginLeft: "10%" } } />
          </div>
          <div
            style={
              {
                fontSize: "large",
                fontWeight: "bold",
                marginTop: "4%",
                textAlign: "center"
              }
            }>
            { this.state.nameSchool }
          </div>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            { this.state.width <= 991 ? <AdminNavbarLinks /> : null }
            { this.props.routes.map((prop, key) => {
              if (prop.invisible) return null;
              if (prop.name === "ASISTENCIAS" && !assist) return null;
              if (prop.name === "COBRANZA" && !payments) return null;
              if (!isTutor && prop.tutor) { return null; }
              else {
                if (!prop.redirect && this.state.privileges.search(prop.letter) !== -1)
                  return (
                    <li
                      className={
                        prop.upgrade
                          ? "active active-pro"
                          : this.activeRoute(prop.layout + prop.path)
                      }
                      key={ key }
                    >
                      <NavLink
                        to={ prop.disabled ? "#" : prop.layout + prop.path }
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className={ prop.icon } />
                        <p>{ prop.name }</p>
                      </NavLink>
                    </li>
                  );
              }
              return null;
            }) }
          </ul>
        </div>
      </div >
    );
  }
}

export default Sidebar;
