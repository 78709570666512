import React, { Component } from "react";
import {
  FormControl,
  Col
} from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import { uploadRoute } from "variables/DomainRoutes.jsx";

class AttachedImage extends Component {
  _remove = () => {
    if (this.props.onRemove)
      this.props.onRemove();
  }
  onChange = async (e) => {
    let filename;
    this.props.showModal();
    try {
      const formData = new FormData();
      formData.append('general_file', e.target.files[0])
      formData.append('type', 2);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      filename = await axios.post(uploadRoute + 'general/upload', formData, config);
      filename = filename.data.filename
      console.log(filename)
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.props.onChangeAttached(
        { target: { name: `image${this.props.name}`, value: filename } }
      );
      this.props.closeModal();
    }

  }
  render() {
    const {
      name,
      canDelete
    } = this.props;
    return (
      <Col md={ 10 } style={ { margin: "1%" } }>
        <Col md={ 8 }>
          <FormControl type="file" name={ "image" + name } onChange={ e => this.onChange(e) } accept="image/*" />
        </Col>
        {
          canDelete &&
          <Button bsStyle="danger" bsSize="xs" fill onClick={ () => this._remove(this) }>
            <i className="fa fa-trash"></i>
          </Button>
        }
      </Col>
    )
  }
}

export default AttachedImage;