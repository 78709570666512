/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";

class AdminNavbarLinks extends Component {
  render() {
    const { userName } = this.props;
    return (
      <div>
        <Nav pullRight>
          <NavDropdown
            eventKey={2}
            title={userName}
            id="basic-nav-dropdown-right"
          >
            <MenuItem
              eventKey={6}
              onClick={() => {
                localStorage.removeItem('TOKEN');
                localStorage.removeItem('USER');
                localStorage.removeItem('INSTITUTION');
                this.props.history.replace('/login');
              }}
            >Salir</MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
