import React from 'react'
import { Modal } from "react-bootstrap";
import Loader from 'react-loader-spinner'

const LoadingModal = ({ show, text }) => {
  return (
    <Modal show={ show } backdrop="static">
      <Modal.Header>
        <Modal.Title>{ text }</Modal.Title>
      </Modal.Header>
      <Modal.Body style={ { wordWrap: "break-word" } }>
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { marginLeft: "40%" } }
        />
      </Modal.Body>
    </Modal >
  );
};
export default LoadingModal;