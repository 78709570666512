import React, { Component } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import styles from "./styles";
import { periodTimes } from "variables/Variables.jsx";

class ControlGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProgramed: false,
      isRepeated: false,
      fastResponse: false
    };
    this.hours = [
      "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00",
      "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
      "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00",
    ];
    this.minutes = [
      "00",
      "15",
      "30",
      "45"
    ]
  }

  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    const {
      isProgramed,
      isRepeated,
      fastResponse
    } = this.state;

    return (
      <Row>
        <Col md={ 6 } style={ { marginLeft: "2%" } }>
          <Checkbox
            number="1"
            checked={ fastResponse }
            label="Permitir respuesta rápida"
            onChange={ () => {
              this.setState({ fastResponse: !this.state.fastResponse })
              this.props.onChange({ target: { name: 'fastResponse', value: !this.state.fastResponse } })
            } }
          />
          <Col style={ { marginTop: "20%" } }>
            <Checkbox
              number="2"
              checked={ isProgramed }
              label="¿Es un mensaje programado?"
              onChange={ () => {
                this.setState({ isProgramed: !this.state.isProgramed })
                this.props.onChange({ target: { name: 'programmed', value: !this.state.isProgramed } })
              } }
            />
          </Col>

          <FormGroup>
            <ControlLabel style={ styles.content }> FECHA DE ENVÍO </ControlLabel>
            <FormControl type="date" name="dateSend" onChange={ e => this.onChange(e) } disabled={ !isProgramed } />
          </FormGroup>
          <FormGroup>
            <Col md={ 12 }>
              <ControlLabel style={ styles.content }> HORA DE ENVÍO </ControlLabel>
            </Col>

            <FormControl componentClass="select" name="sendTime" onChange={ e => this.onChange(e) } disabled={ !isProgramed }>
              <option value="">Elija una hora</option>
              { this.hours.map((item) =>
                <option key={ item } value={ item }>{ item }</option>
              ) }
            </FormControl>
            <FormControl componentClass="select" name="sendTime1" onChange={ e => this.onChange(e) } disabled={ !isProgramed } style={ { marginTop: "1%" } }>
              { this.minutes.map((item) =>
                <option key={ item } value={ item }>{ item }  minutos </option>
              ) }
            </FormControl>



          </FormGroup>


          <Checkbox
            number="3"
            checked={ isRepeated }
            label="Repetir"
            onChange={ () => {
              this.setState({ isRepeated: !this.state.isRepeated })
              this.props.onChange({ target: { name: 'repeat', value: !this.state.isRepeated } })
            } }
          />
          <ControlLabel style={ styles.content }> PERIODO </ControlLabel>
          <FormControl componentClass="select" placeholder="select" name="period" onChange={ e => this.onChange(e) } disabled={ !isRepeated }>
            <option value="">Escoja una opción</option>
            { periodTimes.map((item) =>
              <option key={ item.id } value={ item.id }>{ item.name }</option>
            ) }
          </FormControl>
          <FormGroup>
            <ControlLabel style={ styles.content }> FECHA FIN </ControlLabel>
            <FormControl type="date" name="dateEnd" onChange={ e => this.onChange(e) } disabled={ !isRepeated } />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default ControlGroup;
