import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import CycleModal from 'components/Modal/CycleModal'
import { mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import ConfirmActionModal from 'components/Modal/ConfirmActionModal.jsx';

class ConfCycles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      cycles: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      currentPage: 1,
      selectedCycle: null,
      showCycleModal: false,
      showConfirmDelete: false,
      notificationMessage: '',
      showNotification: false,
      showPassModal: false,
      isDeleting: false,
    };
  }

  async componentDidMount() {
    this.fetchCycles();
  }

  fetchCycles = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('cycle/list', {
        current_page: currentPage,
      });
      this.setState({
        cycles: response.data.cycles.data,
        totalOfPages: response.data.cycles.last_page,
        totalOfRecords: response.data.cycles.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }
  handleOpenConfirmDelete = (cycle) => {
    this.setState({
      showConfirmDelete: true,
      actionName: cycle.name,
      selectedCycle: cycle,
    });
  };

  handleDelete = async () => {
    const { selectedCycle } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`cycle/delete/${selectedCycle.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchCycles();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      this.setState({
        showNotification: true,
        notificationMessage: mensajeError500
      });
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  handlePass = async () => {
    this.setState({ isDeleting: true });
    try {
      await axios.post(`student/up-grade`);
      this.setState({ showPassModal: false });
      this.fetchCycles();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showPassModal: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }


  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchCycles() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchCycles() }
    );
  }

  render() {
    const {
      isLoading,
      cycles,
      totalOfPages,
      totalOfRecords,
      currentPage,
      showPassModal,
      showCycleModal,
      showConfirmDelete,
      notificationMessage,
      showNotification,
      actionName,
      selectedCycle
    } = this.state;
    return !isLoading ? (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <Row style={ { marginTop: "2%" } }>
                { showNotification &&
                  <Col md={ 11 }>
                    <ErrorNotification
                      notificationMessage={ notificationMessage }
                      closeNotification={ () => this.setState({ showNotification: false }) }
                    />
                  </Col>
                }
                <Col md={ 6 }>

                  { showCycleModal &&
                    <CycleModal
                      onClose={ () => this.setState({ showCycleModal: false, selectedCycle: null }) }
                      show={ showCycleModal }
                      cycleData={ selectedCycle }
                      reload={ () => { this.fetchCycles() } }
                    /> }
                  {
                    showConfirmDelete &&
                    <ConfirmDeleteModal
                      onClose={ () => this.setState({ showConfirmDelete: false }) }
                      show={ showConfirmDelete }
                      thingToRemoveType="ciclo"
                      thingToRemoveName={ actionName }
                      onConfirm={ () => this.handleDelete() }
                    />
                  }
                  {
                    showPassModal &&
                    <ConfirmActionModal
                      onClose={ () => this.setState({ showPassModal: false }) }
                      show={ showPassModal }
                      actionTitle=" pase de ciclo"
                      actionBody="Al presionar este botón todo los alumnos pasaran al siguiente ciclo. ¿Desea continuar?"
                      onConfirm={ () => this.handlePass() }
                    />
                  }
                  <Button
                    bsStyle="primary" fill
                    style={ { margin: "1%" } }
                    onClick={ () => this.setState({ showCycleModal: true }) }
                  >
                    Agregar ciclo
                  </Button>
                  <Button
                    bsStyle="primary" fill
                    style={ { margin: "1%" } }
                    onClick={ () => this.setState({ showPassModal: true }) }
                  >
                    Pase de ciclo
                  </Button>
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      { cycles.map((cycle) => {
                        return (
                          <tr key={ cycle.id }>
                            <td>{ cycle.name }</td>
                            <td>
                              <Button bsStyle="success" bsSize="sm" fill title="Editar nombre"
                                onClick={ () => this.setState({ showCycleModal: true, selectedCycle: cycle }) }
                              ><i className="fa fa-edit"></i></Button>
                              <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(cycle) } ><i className="fa fa-trash"></i></Button>
                            </td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                  { cycles.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={ currentPage === 1 }
                          onClick={ this.goToPreviousPage }
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                        <Pager.Item>

                          { currentPage }/{ totalOfPages }

                        </Pager.Item>
                        <Pager.Item
                          disabled={ currentPage === totalOfPages }
                          next
                          onClick={ this.goToNextPage }
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: { totalOfRecords } registros
                    </Pager.Item>
                      </Pager>
                    </>
                  ) }
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default ConfCycles;
