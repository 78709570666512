import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import Loader from 'react-loader-spinner'
import { mensajeError500, tdCourses } from "variables/Variables.jsx";
import axios from 'components/axios';
import CourseModal from 'components/Modal/CourseModal';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedCourse: null,
      courses: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      currentPage: 1,
      showNotification: false,
      notificationMessage: '',
      showCourseModal: false,
    };
  }

  async componentDidMount() {
    this.fetchCourses();
  }

  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchCourses() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchCourses() }
    );
  }

  fetchCourses = async () => {
    const { currentPage } = this.state;
    this.setState({ courses: tdCourses });
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('course/list', {
        current_page: currentPage,
      });
      this.setState({
        courses: response.data.courses.data,
        totalOfPages: response.data.courses.last_page,
        totalOfRecords: response.data.courses.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  handleOpenConfirmDelete = (course) => {
    this.setState({
      showConfirmDelete: true,
      actionName: `${course.name} (${course.code})`,
      selectedCourse: course,
    });
  };

  handleDelete = async () => {
    const { selectedCourse } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`/course/delete/${selectedCourse.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchCourses();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      currentPage,
      showConfirmDelete,
      actionName,
      isLoading,
      courses,
      showNotification,
      notificationMessage,
      showCourseModal,
      selectedCourse,
      totalOfPages,
      totalOfRecords
    } = this.state;
    return !isLoading ? (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <ConfirmDeleteModal
                onClose={ () => this.setState({ showConfirmDelete: false }) }
                show={ showConfirmDelete }
                prepo="a la"
                thingToRemoveType="materia"
                thingToRemoveName={ actionName }
                onConfirm={ this.handleDelete }
              />
              <Row>
                <Col md={ 12 }>
                  { showNotification &&
                    <Col md={ 11 }>
                      <ErrorNotification
                        notificationMessage={ notificationMessage }
                        closeNotification={ () => this.setState({ showNotification: false }) }
                      />
                    </Col>
                  }
                  { showCourseModal &&
                    <CourseModal
                      onClose={ () => this.setState({ showCourseModal: false, selectedCourse: null }) }
                      show={ showCourseModal }
                      courseData={ selectedCourse }
                      reload={ () => { this.setState({ showCourseModal: false, selectedCourse: null }); this.fetchCourses() } }
                    /> }
                  <Button
                    bsStyle="primary" fill
                    style={ { margin: "1%" } }
                    onClick={ () => this.setState({ showCourseModal: true }) }
                  >
                    Agregar Materia
                  </Button>
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Código</th>
                        <th>Grupos Asignados</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      { courses.map((course) => {
                        return (
                          <tr key={ course.id }>
                            <td>{ course.name }</td>
                            <td>{ course.code }</td>
                            <td>{ course.assigned }</td>
                            <td>
                              <Button
                                bsStyle="success" bsSize="sm" fill title="Editar nombre"
                                onClick={ () => this.setState({ showCourseModal: true, selectedCourse: course }) }
                              ><i className="fa fa-edit"></i></Button>
                              <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(course) } disabled={ course.assigned > 0 } ><i className="fa fa-trash"></i></Button>
                            </td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                  { courses.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={ currentPage === 1 }
                          onClick={ this.goToPreviousPage }
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                        <Pager.Item>

                          { currentPage }/{ totalOfPages }

                        </Pager.Item>
                        <Pager.Item
                          disabled={ currentPage === totalOfPages }
                          next
                          onClick={ this.goToNextPage }
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: { totalOfRecords } registros
                        </Pager.Item>
                      </Pager>
                    </>
                  ) }
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default Course;
