import React, { Component } from "react";
import {
  Grid
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import MonitorList from 'views/monitor/MonitorList';
import MonitorDetail from 'views/monitor/MonitorDetail';

class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      monitorData: {},
      currentPage: 1,
    };
  }

  handleBackToList = () => {
    this.setState({ key: 0 });
  };

  handleDetailMonitor = (monitorData) => {
    this.setState({ key: 1, monitorData: monitorData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      monitorData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: (
                    <MonitorList
                      detailMonitor={ this.handleDetailMonitor }
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                    />
                  ),
                  1: <MonitorDetail returnToList={ this.handleBackToList } monitorData={ monitorData } />,
                }[key]
              }

            </Grid>
          }
        />
      </div>
    );
  }
}

export default Monitor;
