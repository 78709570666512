import React, { Component } from "react";
import {
  Row,
  Col,
  ControlLabel
} from "react-bootstrap";
import ContentMessage from "views/messages/components/ContentMessage";
import { mensajeError500 } from "variables/Variables.jsx";
import { Card } from "components/Card/Card.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import styles from "./styles";
import Button from 'components/CustomButton/CustomButton';

class NewHomework extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: '',
      url1: '',
      url2: '',
      url3: '',
      url4: '',
      url5: '',
      attached1: '',
      attached2: '',
      attached3: '',
      attached4: '',
      attached5: '',
      isSubmitting: false,
      isLoading: false,
      teachingsCourses: [],
      TCId: '',
    };
  }

  async componentDidMount() {
    this.fetchTeachingCourses();
  }


  fetchTeachingCourses = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('teaching_course/list_select', {
        current_page: 1
      });
      this.setState({ teachingsCourses: response.data.teachings_courses })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleContent = (p) => {
    this.setState({ [p.target.name]: p.target.value });
  }

  onEditorChange = (evt) => {
    this.setState({
      body: evt.editor.getData()
    });
  }

  joinAttached = () => {
    const {
      attached1,
      attached2,
      attached3,
      attached4,
      attached5,
    } = this.state;
    let attached_str = "";
    let attacheds = [];
    attacheds.push(attached1)
    attacheds.push(attached2)
    attacheds.push(attached3)
    attacheds.push(attached4)
    attacheds.push(attached5)
    for (let index = 0; index < 5; index++) {
      if (attacheds[index] !== "") {
        if (index === 0) {
          attached_str = attached_str + attacheds[index]
        } else {
          attached_str = attached_str + "," + attacheds[index]
        }
      }
    }
    return attached_str
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      TCId,
      body,
      url1,
      url2,
      url3,
      url4,
      url5,
    } = this.state;
    if (TCId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo ASIGNACIÓN DE TAREA"
      });
      return;
    }

    this.setState({ isSubmitting: true });
    try {
      await axios.post('homework/add', {
        msg: body,
        url1,
        url2,
        url3,
        url4,
        url5,
        teachings_course_id: TCId,
        files: this.joinAttached()
      });
      this.props.history.replace("/admin/homework/index")
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      isLoading,
      teachingsCourses
    } = this.state
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row style={ { paddingLeft: "30px" } }>
                <h4>Asignar tarea</h4>
                { showNotification &&
                  <Col md={ 12 } >
                    <ErrorNotification
                      notificationMessage={ notificationMessage }
                      closeNotification={ () => this.setState({ showNotification: false }) }
                    />
                  </Col>
                }
                <Col md={ 1 } ></Col>
                <Col md={ 6 } >
                  <Col md={ 10 }>
                    <ControlLabel > ASIGNACIÓN DE TAREA </ControlLabel>
                    { !isLoading ? (
                      <Select options={ teachingsCourses }
                        name="TCId" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => this.setState({ 'TCId': e.value }) }
                        placeholder="Seleccione una opción" />
                    ) : (
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 20 }
                          width={ 20 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                      ) }
                  </Col>
                  <Col md={ 12 }>
                    <ContentMessage onChange={ this.handleContent } onEditorChange={ this.onEditorChange } noSubject={ true } />
                  </Col>
                </Col>
                <Row>
                  <Col md={ 12 } style={ { marginLeft: "0px" } } >
                    <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                      <i className="fa fa-save"></i> Enviar
                    </Button>
                    <Button bsStyle="danger" pullRight fill type="button" style={ styles.saveButton } onClick={ () => this.props.history.replace("/admin/homework/index") }>
                      <i className="fa fa-chevron-left"></i> Cancelar
                    </Button>
                  </Col>
                </Row>
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default NewHomework;
