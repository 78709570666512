

import React from 'react'
import {
  Table,
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import DetailMonitorModal from 'components/Modal/DetailMonitorModal.jsx'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
class MonitorDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isObsEdit: false,
      monitor: {},
      details: [],
      obs: '',
      oldObs: '',
      isSubmittingEdit: true,
      showDetailMonitorModal: false,
      selectedData: null,
      showConfirmDelete: false,
      actionName: ''
    };
  }

  async componentDidMount() {
    this.fetchMonitorDetail();
  }

  fetchMonitorDetail = async () => {
    this.setState({ isLoading: true });
    const { monitorData: { id } } = this.props;
    try {
      const response = await axios.post('monitory/details', {
        monitory_id: id
      });
      this.setState({
        monitor: response.data.Monitory,
        details: response.data.Monitory.details,
        obs: response.data.Monitory.observations,
        oldObs: response.data.Monitory.observations
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSaveObs() {
    this.setState({
      oldObs: this.state.obs,
      isObsEdit: false
    });
  }
  handleSaveObs = async () => {
    const { monitorData: { id } } = this.props;
    const { obs } = this.state;
    this.setState({ isSubmittingEdit: true });
    try {
      await axios.post('monitory/edit/' + id, {
        observations: obs,
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmittingEdit: false, isObsEdit: false, oldObs: obs });
    }
  }
  handleOpenConfirmDelete = (detail) => {
    this.setState({
      showConfirmDelete: true,
      actionName: detail.name,
      selectedData: detail,
    });
  };
  handleDelete = async () => {
    const { selectedData } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`monitory/deletedetails/${selectedData.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchMonitorDetail();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isDeleting: false });
    }
  }
  render() {
    const {
      returnToList
    } = this.props;
    const {
      isLoading,
      monitor,
      isObsEdit,
      obs,
      oldObs,
      details,
      notificationMessage,
      showNotification,
      isSubmittingEdit,
      showDetailMonitorModal,
      selectedData,
      showConfirmDelete,
      actionName
    } = this.state;
    return !isLoading ? (
      <>
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <Row style={ { marginTop: "2%" } }>
              <Col md={ 4 }>
                <h4>Datos del seguimiento</h4>
                <FormGroup>
                  <ControlLabel> USUARIO </ControlLabel>
                  <span style={ styles.data }>
                    { monitor.user && `${monitor.user.name} ${monitor.user.last_name}` }
                  </span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> PADRE </ControlLabel>
                  <span style={ styles.data }>
                    { monitor.tutor && `${monitor.tutor.name} ${monitor.tutor.last_name}` }
                  </span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> ESTUDIANTE</ControlLabel>
                  <span style={ styles.data }>
                    { monitor.student && `${monitor.student.name} ${monitor.student.last_name}` }
                  </span>
                </FormGroup>

                <FormGroup>
                  <ControlLabel> ENVIADO</ControlLabel>
                  <span style={ styles.data }>{ monitor.created_at }</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> LEIDO</ControlLabel>
                  <span style={ styles.data }>{ monitor.state === false ? <span className="label label-danger">No</span> : <span className="label label-success">Si</span> }</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel> VISTO</ControlLabel>
                  <span style={ styles.data }>{ monitor.viewed === null ? "-" : monitor.viewed }</span>
                </FormGroup>
              </Col>
              <Col md={ 8 }>
                <h4>Detalles del seguimiento</h4>
                <Row>
                  { showNotification &&
                    <Col md={ 11 }>
                      <ErrorNotification
                        notificationMessage={ notificationMessage }
                        closeNotification={ () => this.setState({ showNotification: false }) }
                      />
                    </Col>
                  }
                  { showDetailMonitorModal &&
                    <DetailMonitorModal
                      onClose={ () => this.setState({ showDetailMonitorModal: false, selectedData: null }) }
                      show={ showDetailMonitorModal }
                      monitorDetailData={ selectedData }
                      monitorId={ monitor.id }
                      reload={ () => this.fetchMonitorDetail() }
                    /> }
                  <Col md={ 12 }>
                    <ControlLabel> OBSERVACIONES</ControlLabel>
                  </Col>

                  <Row>
                    { isSubmittingEdit === "true" ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      /> :
                      <>
                        <Col md={ 8 }>
                          <FormGroup>
                            {
                              isObsEdit ?
                                <FormControl type="text" name="obs" value={ obs } onChange={ e => this.onChange(e) } required />
                                :
                                <span style={ styles.data }>{ oldObs }</span>
                            }
                          </FormGroup>
                        </Col>
                        <Col md={ 2 }>
                          { isObsEdit ?
                            <>
                              <Button
                                bsStyle="danger"
                                fill
                                bsSize="sm"
                                onClick={ () => this.setState({ isObsEdit: false, obs: oldObs }) }
                                title="Descartar cambios"
                              ><i className="fa fa-times"></i> </Button>
                              <Button
                                bsStyle="success"
                                fill
                                bsSize="sm"
                                title="Guardar cambios"
                                onClick={ () => this.handleSaveObs() }
                              ><i className="fa fa-save"></i></Button>
                            </>
                            :
                            <Button
                              bsStyle="success"
                              fill
                              bsSize="sm"
                              onClick={ () => this.setState({ isObsEdit: true }) }
                              title="Editar observaciones"
                            ><i className="fa fa-edit"></i> </Button>
                          }
                        </Col>
                      </>

                    }

                  </Row>
                </Row>
                <ConfirmDeleteModal
                  onClose={ () => this.setState({ showConfirmDelete: false }) }
                  show={ showConfirmDelete }
                  thingToRemoveType="detalle de seguimiento académico"
                  thingToRemoveName={ actionName }
                  onConfirm={ this.handleDelete }
                />
                <Button
                  bsStyle="primary"
                  fill
                  onClick={ () => this.setState({ showDetailMonitorModal: true }) }
                >Agregar detalle</Button>
                <br />
                { details.length > 0 ?
                  <Table hover style={ { marginTop: "2%" } }>
                    <thead>
                      <tr>
                        <th>Nombre </th>
                        <th>Valor</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      { details.map((det) => {
                        return (
                          <tr key={ det.id }>
                            <td>{ det.name }</td>
                            <td>{ det.attribute && det.attribute.name }</td>
                            <td>
                              <Button
                                bsStyle="success"
                                bsSize="sm"
                                fill
                                title="Editar"
                                onClick={ () => this.setState({ showDetailMonitorModal: true, selectedData: det }) }
                              ><i className="fa fa-edit"></i></Button>
                              <Button
                                bsStyle="danger"
                                bsSize="sm"
                                fill
                                title="Eliminar"
                                onClick={ () => this.handleOpenConfirmDelete(det) }
                              ><i className="fa fa-trash"></i></Button>
                            </td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table> :
                  <div style={ { marginTop: "2%" } }>No tiene detalles aún</div>
                }
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                </Button>
              </Col>
            </Row>
          </Col >
        </Row >
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default MonitorDetail