import React, { Component } from "react";
import {
    Row,
    Col
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import styles from "./styles";
import AttachedItem from "./AttachedItem";
import AddButton from "./AddButton";
import UrlItem from "./UrlItem";
import CKEditor from 'ckeditor4-react';
import LoadingModal from "components/Modal/LoadingModal";

class ContentMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attached: 0,
            attachedData: [],
            url: 0,
            urlData: [],
            showLoadingModal: false
        };
    }

    onChange(e) {
        this.props.onChange(e);
    }

    onEditorChange(e) {
        this.props.onEditorChange(e);
    }
    onChangeUrl = (p) => {
        this.props.onChange(p);
    }

    showModal = () => {
        this.setState({ showLoadingModal: true })
    }

    closeModal = () => {
        this.setState({ showLoadingModal: false })
    }

    _add() {
        let { attachedData } = this.state;
        let newData = [
            ...attachedData,
            {
                name: "attached",
            }
        ]
        this.setState(prevState => ({
            attached: prevState.attached + 1,
            attachedData: newData
        }))
    }

    _addUrl = () => {
        let { urlData } = this.state;
        let newName = urlData.length;
        let newData = [
            ...urlData,
            {
                [newName]: "",
            }
        ]
        this.setState(prevState => ({
            url: prevState.url + 1,
            urlData: newData
        }))
    }


    _remove(position) {
        let { attachedData } = this.state;
        let newData = [
            ...attachedData.slice(0, position),
            ...attachedData.slice(position + 1),
        ]
        this.setState(prevState => ({
            attached: prevState.attached - 1,
            attachedData: newData
        }))
        this.props.onChange({ target: { name: `attached${position + 1}`, value: '' } })
    }

    _removeUrl(position) {
        let { urlData } = this.state;
        let newData = [
            ...urlData.slice(0, position),
            ...urlData.slice(position + 1),
        ]
        this.setState(prevState => ({
            url: prevState.url - 1,
            urlData: newData
        }))
        this.props.onChange({ target: { name: `url${position + 1}`, value: '' } })
    }

    render() {
        const {
            noSubject,
        } = this.props;
        const {
            attached,
            url,
            showLoadingModal
        } = this.state;
        return (
            <Row>
                <LoadingModal
                    show={ showLoadingModal }
                    text="Espere, se está adjuntando el archivo seleccionado ..."
                />
                <Col md={ 12 }>
                    {
                        !noSubject &&
                        <FormGroup>
                            <ControlLabel style={ styles.content }> ASUNTO </ControlLabel>
                            <FormControl type="text" name="subject" onChange={ e => this.onChange(e) } style={ { width: "100%" } } required />
                        </FormGroup>
                    }

                    <FormGroup>
                        <ControlLabel > MENSAJE </ControlLabel>
                        <Row>
                            <CKEditor
                                onChange={ e => this.onEditorChange(e) }
                                config={ {
                                    toolbar: [
                                        [
                                            'Bold', 'Italic', 'Strike', '-',
                                            'RemoveFormat', '-',
                                            'Undo', 'Redo', '-',
                                            'NumberedList', 'BulletedList', '-',
                                            'Outdent', 'Indent', '-',
                                            'Styles', 'Format', '-',
                                            'Blockquote', '-', 'Maximize'
                                        ]
                                    ]
                                } }
                            />
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Col md={ 12 }>
                            <ControlLabel > ARCHIVOS ADJUNTOS</ControlLabel>
                            <AddButton name="Añadir adjunto" onClick={ this._add.bind(this) } numAttached={ attached } />
                        </Col>
                        <Col md={ 12 }>

                            { this.state.attachedData.map(
                                (item, index) =>
                                    <AttachedItem
                                        data={ item }
                                        key={ index }
                                        name={ index + 1 }
                                        canDelete={ this.state.attachedData.length - 1 === index }
                                        onRemove={ () => this._remove(index) }
                                        onChangeAttached={ e => this.onChange(e) }
                                        showModal={ this.showModal }
                                        closeModal={ this.closeModal }
                                    />
                            )
                            }

                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={ 12 }>
                            <ControlLabel > URLs</ControlLabel>
                            <AddButton name="Añadir url" onClick={ () => this._addUrl(this) } numAttached={ url } />
                        </Col>
                        <Col md={ 12 }>

                            { this.state.urlData.map(
                                (item, index) =>
                                    <UrlItem
                                        name={ index + 1 }
                                        key={ index }
                                        onChangeUrl={ e => this.onChangeUrl(e) }
                                        onRemove={ () => this._removeUrl(index) }
                                        canDelete={ this.state.urlData.length - 1 === index }
                                    />
                            )
                            }

                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

export default ContentMessage;