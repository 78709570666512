import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class MonitorRecorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      monitories: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      currentPage: 1,
    };
  }
  async componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/monitory/register/deleted', {
        current_page: currentPage,
      });
      this.setState({
        monitories: response.data.monitory_register.data,
        totalOfPages: response.data.monitory_register.last_page,
        totalOfRecords: response.data.monitory_register.total
      });
      console.log("record")
      console.log(response.data.monitory_register.data)
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchMessages() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchMessages() }
    );
  }

  render() {
    const {
      isLoading,
      monitories,
      totalOfPages,
      totalOfRecords,
      currentPage
    } = this.state;

    return !isLoading ? (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 12 }>
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th>Usuario </th>
                        <th>Padre</th>
                        <th>Estudiante</th>
                        <th>Enviado</th>
                        <th>Responsable</th>
                        <th>Fecha de eliminación</th>
                      </tr>
                    </thead>
                    <tbody>
                      { monitories.map((record) => {
                        return (
                          <tr key={ record.record_id }>
                            <td>
                              { record.emisor && `${record.emisor.name} ${record.emisor.last_name}` }
                            </td>
                            <td>
                              { record.tutor && `${record.tutor.name} ${record.tutor.last_name}` }
                            </td>
                            <td>
                              { record.student && `${record.student.name} ${record.student.last_name}` }
                            </td>
                            <td>{ record.send_date }</td>
                            <td>{ record.responsable.name }{ record.responsable.last_name }</td>
                            <td>{ record.created_at }</td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                  { monitories.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={ currentPage === 1 }
                          onClick={ this.goToPreviousPage }
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                        <Pager.Item>

                          { currentPage }/{ totalOfPages }

                        </Pager.Item>
                        <Pager.Item
                          disabled={ currentPage === totalOfPages }
                          next
                          onClick={ this.goToNextPage }
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: { totalOfRecords } registros
                        </Pager.Item>
                      </Pager>
                    </>
                  ) }
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MonitorRecorList;
