import React, { Component } from "react";
import {
    FormControl,
    Col
} from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import { uploadRoute } from "variables/DomainRoutes.jsx";


class AttachedItem extends Component {
    _remove = () => {
        if (this.props.onRemove)
            this.props.onRemove();
    }
    onChange = async (e) => {
        let filename;
        const MAXIMO = 5000000;
        this.props.showModal();
        try {

            const archivo = e.target.files[0];
            if (archivo.size > MAXIMO) {
                const tamanioEnMb = MAXIMO / 1000000;
                alert(`El tamaño máximo es ${tamanioEnMb} MB, intente mandar un archivo más pequeño.`);
                e.target.files.length = 0
                return;
            }
            const formData = new FormData();
            formData.append('general_file', e.target.files[0])
            formData.append('type', 2);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            filename = await axios.post(uploadRoute + 'general/upload', formData, config);
            filename = filename.data.filename

        } catch (err) {
            console.log('ERROR', err);
        } finally {
            this.props.onChangeAttached(
                { target: { name: `attached${this.props.name}`, value: filename } }
            );
            this.props.closeModal();
        }

    }
    render() {
        const {
            name,
            canDelete,
            acceptStr
        } = this.props;
        return (
            <Col md={ 10 } style={ { margin: "1%" } }>
                <Col md={ 8 }>
                    <FormControl
                        type="file"
                        name={ "attached" + name }
                        onChange={ e => this.onChange(e) }
                        accept={ acceptStr } />
                </Col>
                {
                    canDelete &&
                    <Button bsStyle="danger" bsSize="xs" fill onClick={ () => this._remove(this) }>
                        <i className="fa fa-trash"></i>
                    </Button>
                }
            </Col>
        )
    }
}

export default AttachedItem;